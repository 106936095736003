import produce from 'immer';
import * as React from 'react';
import { isTimeslotDateSet } from '../../components/Calendar/TimeSlots';
import { AlertOnHide, AlertType } from '../../context/alertContext';
import { InitialLoginType } from '../../context/loginContext';
import { InitialTerminierungType } from '../../context/terminierungContext';
import { appointmentStatus } from '../../globals/global';
import { IAppointment, getAPKNrFromExtension, getMissingAPKsFromExtension } from '../../utils/appointmentUtils';
import { responseErrorHandling } from '../../utils/errorHandling';
import { getFHIRConfig } from './fhirConstants';
import { setDataFromAppProperties } from '../../utils/pcpUtils';

const fhir = require('fhir.js');

const moment = require('moment');

export interface IAppointmentResult {
    key: string;
    data: IAppointment[];
}

export interface IFreeAppointmentSearch {
    makroId: string;
    date: string;
    physicianId?: string;
    selectedReason?: string;
    selectedDepartment?: string;
    selectedInsurance?: string;
}

export const getAppointmentsOfPatient = async (state: InitialLoginType, alertdispatch: React.Dispatch<any>) => {
    const sessionId = state.sessionId;
    const userId = state.activeUserId;

    return await fhir(getFHIRConfig(sessionId))
        .search({
            type: 'Appointment',
            query: {
                actor: 'Patient/' + userId,
                _sort: '-date',
            },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};

export const getFreeAppointmentTimeSlots = async (
    state: InitialLoginType,
    tmstate: InitialTerminierungType,
    freeAppointment: IFreeAppointmentSearch,
) => {
    const query: any = {
        actor: 'Patient/' + state.activeUserId,
        date: freeAppointment.date,
        _makroId: freeAppointment.makroId,
        _sort: '-date',
    };

    if (freeAppointment.physicianId) {
        query._physicianId = freeAppointment.physicianId;
    }

    if (tmstate.workOnAppointmentNr > 1) {
        const foundSelectedTimeslots = tmstate.selectedTimeslots.find((e) => e.appointmentNr === 1);
        const selectedTimeslot = foundSelectedTimeslots?.selectedTimeslot;
        const res0Date = moment(selectedTimeslot?.start).format('yyyy-MM-DD');

        query.apkNr = tmstate.workOnAppointmentNr - 1;
        query.res0 = res0Date;
    }
    return await fhir(getFHIRConfig(state.sessionId)).search({
        type: 'Appointment',
        query,
    });
};

export const getFreeAppointmentTimeSlotsRenew = async (
    state: InitialLoginType,
    tmstate: InitialTerminierungType,
    freeAppointment: IFreeAppointmentSearch,
) => {
    const foundSelectedTimeslots = tmstate.selectedTimeslots.find((e) => e.appointmentNr === 1);
    const selectedTimeslot = foundSelectedTimeslots?.selectedTimeslot;

    const foundSelectedTimeslots_2 = tmstate.selectedTimeslots.find((e) => e.appointmentNr === 2);
    const selectedTimeslot_2 = foundSelectedTimeslots_2?.selectedTimeslot;

    let res1Date = '';
    let res0Date = '';
    if (isTimeslotDateSet(selectedTimeslot_2)) {
        res1Date = moment(selectedTimeslot_2?.start).format('yyyy-MM-DD');
    }
    if (isTimeslotDateSet(selectedTimeslot)) {
        res0Date = moment(selectedTimeslot?.start).format('yyyy-MM-DD');
    }

    const makroId = tmstate.cancelNewAppointmentData.data.serviceType[0].coding[0].id;

    let apkNr;
    const missingAPK = getMissingAPKsFromExtension(tmstate.cancelNewAppointmentData.data.extension);
    if (missingAPK.length > 0) {
        apkNr = missingAPK;
    } else {
        apkNr = getAPKNrFromExtension(tmstate.cancelNewAppointmentData.data.extension);
    }

    const query: any = {
        actor: 'Patient/' + state.activeUserId,
        date: freeAppointment.date,
        _makroId: makroId,
        _sort: '-date',
        apkNr: apkNr,
        res0: res0Date,
        res1: res1Date,
    };

    if (freeAppointment.physicianId) {
        query._physicianId = freeAppointment.physicianId;
    }

    return await fhir(getFHIRConfig(state.sessionId)).search({
        type: 'Appointment',
        query,
    });
};

export const bookAppointment = async (
    sessionId: string,
    resource: any,
    remark: string,
    earlierAppointmentWanted: boolean,
    id?: string | null,
) => {
    const mResource = produce(resource, (draft: any) => {
        draft.comment = remark;
        draft.earlierAppointmentWanted = earlierAppointmentWanted;
        if (id) {
            draft.id = id;
        }
    });

    return await fhir(getFHIRConfig(sessionId))
        .create({
            type: 'Appointment',
            resource: mResource,
        })
        .catch(function (e: any) {
            if (e?.data?.issue[0]?.diagnostics) {
                const errorMsg = e.data.issue[0].diagnostics;
                return { error: errorMsg };
            } else {
                console.log('An error happened while updating Appointment: \n', e);
            }
        });
};

export const bookMultiAppointment = async (
    state: InitialLoginType,
    resource: any,
    resource_2: any,
    remark: string,
    cancelNewAppointmentData: any,
) => {
    const appointments: any[] = [];

    const request = {
        method: 'POST',
        url: 'Appointment',
    };

    if (cancelNewAppointmentData.data?.contained) {
        /* Ersatztermin Variante */
        let mResource;
        if (resource.status === 'booked' || resource.status === 'fulfilled') {
            mResource = produce(resource_2, (draft: any) => {
                draft.comment = remark;
                draft.status = resource.status;
                draft.contained = resource;
            });
        } else if (resource_2.status === 'booked' || resource_2.status === 'fulfilled') {
            mResource = produce(resource, (draft: any) => {
                draft.comment = remark;
                draft.status = resource_2.status;
                draft.contained = resource_2;
            });
        }

        appointments.push({
            request: request,
            resource: mResource,
        });
    } else {
        /* Normale Variante */
        const mResource = produce(resource, (draft: any) => {
            draft.comment = remark;
            draft.status = 'booked';
        });
        const mResource_2 = produce(resource_2, (draft: any) => {
            draft.comment = remark;
            draft.status = 'booked';
        });

        appointments.push({
            request: request,
            resource: mResource,
        });
        appointments.push({
            request: request,
            resource: mResource_2,
        });
    }

    const trBunde = {
        resourceType: 'Bundle',
        entry: appointments,
        type: 'transaction',
    };

    const config = {
        ...getFHIRConfig(state.sessionId),
    };

    return await fhir(config)
        .transaction({
            type: 'Bundle',
            id: Math.random().toString(),
            resource: trBunde,
        })
        .catch(function (e: any) {
            console.log('An error happened while updating Appointment: \n', e);
        });

    // const mResource = produce(resource, (draft: any) => {
    //     draft.comment = remark;
    // });

    // return await fhir(getFHIRConfig(state.sessionId))
    //     .create({
    //         type: 'Appointment',
    //         resource: mResource,
    //     })
    //     .catch(function (e: any) {
    //         console.log('An error happened while updating Appointment: \n', e);
    //     });
};

export const cancelAppointment = async (
    sessionId: string,
    id: string,
    comment: string,
    alertdispatch: React.Dispatch<any>,
) => {
    const cmt = comment ? comment : 'Absage von Patient';
    return await fhir(getFHIRConfig(sessionId))
        .update({
            type: 'Appointment',
            id,
            resource: {
                id,
                comment: cmt,
                status: 'cancelled',
                resourceType: 'Appointment',
            },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
                alertMessage: {
                    alertTitle: 'Termin stornieren',
                    alertTxt: 'Es ist ein Fehler beim stornieren des Termins aufgetreten',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                },
            });
        });
};

export const structureAppointments = (appointments: IAppointment[]) => {
    const resultsUpcomming: IAppointmentResult[] = [];
    const resultsPast: IAppointmentResult[] = [];

    const todayAppt: IAppointment[] = [];
    const futureAppt: IAppointment[] = [];
    const plannedWithoutDateAppt: IAppointment[] = [];
    const pastAppt: IAppointment[] = [];
    const incompleteAppt: IAppointment[] = [];
    const incompleteApptPast: IAppointment[] = [];
    let incompleteApptTotal: IAppointment[] = [];
    const canceledAppt: IAppointment[] = [];

    const today = moment();
    // console.log('today: ', today.format());

    appointments.forEach((item) => {
        const appointment = item.resource;
        const dateStart = moment(appointment.start);
        const dateEnd = moment(appointment.end);

        // console.log('appointment dateEnd: ', dateEnd.format());
        // console.log('same? ', dateEnd.isBefore(today, 'hour'));

        if (appointment.status === appointmentStatus.enteredInError) {
            if (appointment.start === undefined || dateStart.isSame(today, 'day') || dateStart.isAfter(today)) {
                incompleteAppt.push(appointment);
            } else {
                incompleteApptPast.push(appointment);
            }
            incompleteApptTotal = incompleteAppt.concat(incompleteApptPast);
        } else if (appointment.status === appointmentStatus.cancelled) {
            canceledAppt.push(appointment);
        } else if (appointment.start === undefined) {
            plannedWithoutDateAppt.push(appointment);
        } else {
            // if (dateStart.isSame(today) || (dateStart.isSameOrAfter(today) && dateEnd.isBefore(today))) {
            if (dateStart.isSame(today, 'day')) {
                todayAppt.push(appointment);
            } else if (dateStart.isAfter(today) && dateEnd.isAfter(today)) {
                futureAppt.push(appointment);
            } else if (dateStart.isBefore(today) && dateEnd.isSameOrAfter(today)) {
                todayAppt.push(appointment);
            } else {
                pastAppt.push(appointment);
            }
        }
    });

    resultsUpcomming.push({
        key: 'Heute (' + todayAppt.length + ')',
        data: todayAppt,
    });
    resultsUpcomming.push({
        key: 'Kommende Termine (' + futureAppt.length + ')',
        data: futureAppt,
    });
    resultsUpcomming.push({
        key: 'Geplante Termine ohne Uhrzeit (' + plannedWithoutDateAppt.length + ')',
        data: plannedWithoutDateAppt,
    });

    resultsUpcomming.push({
        key: 'Unvollständige Termine (' + incompleteAppt.length + ')',
        // data: incompleteAppt,
        data: incompleteApptTotal,
    });

    resultsPast.push({
        key: 'Vergangene Termine (' + pastAppt.length + ')',
        data: pastAppt,
    });

    resultsPast.push({
        key: 'Abgesagte Termine (' + canceledAppt.length + ')',
        data: canceledAppt,
    });

    // resultsUpcomming.forEach((obj) => obj.data.forEach((app) => (app.isCancelable = true)));
    resultsUpcomming.forEach((obj) => {
        obj.data.forEach((app) => {
            /* if appointment is an incomplete appointment, check if cancellable, otherwise an upcomming appointment is always cancellable*/
            if (app.status === appointmentStatus.enteredInError) {
                app.isInError = true;
                app.extension.forEach((ext: any) => {
                    if (ext.url.endsWith('isCancellable')) {
                        app.isCancelable = ext.valueBoolean;
                    }
                });
            } else {
                app.isCancelable = true;
            }
        });
    });

    resultsPast.forEach((obj) => {
        obj.data.forEach((app) => {
            /* if appointment is an incomplete appointment, check if cancellable, otherwise an upcomming appointment is always cancellable*/
            if (app.status === appointmentStatus.enteredInError) {
                app.isInError = true;
                app.extension.forEach((ext: any) => {
                    if (ext.url.endsWith('isCancellable')) {
                        app.isCancelable = ext.valueBoolean;
                    }
                });
            } else {
                app.isCancelable = false;
            }
        });
    });

    // console.log('upcoming ', resultsUpcomming);
    // console.log('resultsPast', resultsPast);

    return [resultsUpcomming, resultsPast];
};
