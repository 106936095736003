import moment from 'moment';
import * as React from 'react';
import { InitialLoginType } from '../../context/loginContext';
import { getBirthdayPrettyFormatedWithAge } from '../../utils/dateUtils';
import { responseErrorHandling } from '../../utils/errorHandling';
import { getCountryLongName, getGenderEN } from '../../utils/pcpUtils';
import { IRegisterData } from '../RestServices';
import { getFHIRConfig } from './fhirConstants';
const fhir = require('fhir.js');

export interface IPatientResource {
    id: any;
    name: any;
    birthDate: any;
    address: any;
    gender: any;
    identifier: any;
    telecom: any;
    communication?: any;
    extension?: any;
    managingOrganization?: any;
}

interface IPatientResourceAddress {
    street: string;
    houseNr: string;
}

export interface IPlannedStudies {
    code: string;
    display: string;
    participate: boolean;
}

/**
 *
 * @param sessionId
 *      the sessionId OR OTP variant (PCP otp,userId,to)
 * @param activeUserId
 *      the activeUserId
 * @returns patientResource
 *      the patient resource
 */
export const getPatientResource = async (sessionId: string, activeUserId: string) => {
    return await fhir(getFHIRConfig(sessionId)).read({
        type: 'Patient',
        id: activeUserId,
    });
};

export const getPractitionerResource = async (sessionId: string, practitionerRoleId: string) => {
    return await fhir(getFHIRConfig(sessionId)).read({
        type: 'Practitioner',
        id: practitionerRoleId,
    });
};

export const changePatientResource = async (state: InitialLoginType, resource: any) => {
    return await fhir(getFHIRConfig(state.sessionId)).update({
        type: 'Patient',
        id: state.userId,
        resource: resource.data,
    });
};

const getNewPlannedStudiesEntry = (plannedStudy: IPlannedStudies) => {
    const newEntry = {
        url: 'http://www.principa.siegele-software.com/plannedStudies',
        valueCoding: {
            code: plannedStudy.code,
            display: plannedStudy.display,
            extension: [
                {
                    url: 'http://www.principa.siegele-software.com/plannedStudySelection',
                    valueBoolean: plannedStudy.participate,
                },
            ],
        },
    };
    return newEntry;
};

const createAddressLine = (street: string | undefined, houseNr: string | undefined) => {
    let addressLine = '';
    if (street !== undefined) {
        addressLine += street;
        if (houseNr !== undefined) {
            addressLine += ' ';
        }
    }
    if (houseNr !== undefined) {
        addressLine += houseNr;
    }
    return addressLine;
};

export const updatePatientResourcePlannedStudies = (patientResource: any, newPlannedStudies: IPlannedStudies[]) => {
    if (patientResource.data.extension !== undefined) {
        newPlannedStudies.forEach((plannedStudy) => {
            let foundEntry = false;
            for (let i = 0; i < patientResource.data.extension.length; i++) {
                if (patientResource.data.extension[i].url.endsWith('plannedStudies')) {
                    if (patientResource.data.extension[i].valueCoding?.code === plannedStudy.code) {
                        for (let j = 0; j < patientResource.data.extension[i].valueCoding?.extension.length; j++) {
                            if (
                                patientResource.data.extension[i].valueCoding.extension[j]?.url.endsWith(
                                    'plannedStudySelection',
                                )
                            ) {
                                foundEntry = true;
                                patientResource.data.extension[i].valueCoding.extension[j].valueBoolean =
                                    plannedStudy.participate;
                            }
                        }
                    }
                }
            }
            if (!foundEntry) {
                patientResource.data.extension[patientResource.data.extension.length] =
                    getNewPlannedStudiesEntry(plannedStudy);
            }
        });
    } else {
        const studyEntries: any[] = [];
        newPlannedStudies.forEach((plannedStudy) => {
            studyEntries.push(getNewPlannedStudiesEntry(plannedStudy));
        });

        patientResource.data.extension = [...studyEntries];
        // patientResource.data.extension = [studyEntries[0]];
    }
    return patientResource;
};

export const modifyPatientResource = (originalPatientResource: any, newData: IRegisterData) => {
    let newPatientResource = {} as any;

    newPatientResource = originalPatientResource;

    if (newData.birthdate !== undefined) {
        const formatedBirthdate = moment(newData.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        newPatientResource.data.birthDate = formatedBirthdate;
    }
    if (newData.city !== undefined) {
        if (newPatientResource.data.address !== undefined) {
            for (let i = 0; i < newPatientResource.data.address.length; i++) {
                if (newPatientResource.data.address[i].use === 'home') {
                    newPatientResource.data.address[i].city = newData.city;
                    break;
                }
            }
        } else {
            newPatientResource.data.address = [
                {
                    city: newData.city,
                    use: 'home',
                },
            ];
        }
    }
    if (newData.country !== undefined) {
        if (newPatientResource.data.address !== undefined) {
            for (let i = 0; i < newPatientResource.data.address.length; i++) {
                if (newPatientResource.data.address[i].use === 'home') {
                    newPatientResource.data.address[i].country = newData.country;
                    break;
                }
            }
        } else {
            newPatientResource.data.address = [
                {
                    country: newData.country,
                },
            ];
        }
    }
    if (newData.email !== undefined) {
        if (newPatientResource.data.telecom !== undefined) {
            for (let i = 0; i < newPatientResource.data.telecom.length; i++) {
                if (newPatientResource.data.telecom[i].system === 'email') {
                    newPatientResource.data.telecom[i].value = newData.email;
                    break;
                }
            }
        } else {
            newPatientResource.data.telecom = [
                {
                    system: 'email',
                    value: newData.email,
                },
            ];
        }
    }
    if (newData.gender !== undefined) {
        newPatientResource.data.gender = getGenderEN(newData.gender);
    }

    if (newData.insuranceNr !== undefined) {
        if (newPatientResource.data.identifier !== undefined) {
            let foundInsuranceNrEntry = false;
            for (let i = 0; i < newPatientResource.data.identifier.length; i++) {
                if (newPatientResource.data.identifier[i].system === 'http://fhir.de/NamingSystem/gkv/kvid-10') {
                    foundInsuranceNrEntry = true;
                    newPatientResource.data.identifier[i].value = newData.insuranceNr;
                    break;
                }
            }
            if (!foundInsuranceNrEntry) {
                newPatientResource.data.identifier[newPatientResource.data.identifier.length] = {
                    system: 'http://fhir.de/NamingSystem/gkv/kvid-10',
                    value: newData.insuranceNr,
                };
            }
        } else {
            newPatientResource.data.identifier = [
                {
                    system: 'http://fhir.de/NamingSystem/gkv/kvid-10',
                    value: newData.insuranceNr,
                },
            ];
        }
    }

    if (newData.nameFirst !== undefined) {
        for (let i = 0; i < newPatientResource.data.name.length; i++) {
            if (newPatientResource.data.name[i].use === 'usual') {
                newPatientResource.data.name[i].given[0] = newData.nameFirst;
                break;
            }
        }
    }
    if (newData.nameLast !== undefined) {
        for (let i = 0; i < newPatientResource.data.name.length; i++) {
            if (newPatientResource.data.name[i].use === 'usual') {
                newPatientResource.data.name[i].family = newData.nameLast;
                break;
            }
        }
    }
    if (newData.academicTitle !== undefined) {
        let prefixPosition = 0;
        for (let i = 0; i < newPatientResource.data.name.length; i++) {
            if (newPatientResource.data.name[i].use === 'usual') {
                if (newPatientResource.data.name[i]._prefix !== undefined) {
                    for (let j = 0; j < newPatientResource.data.name[i]._prefix.length; j++) {
                        if (newPatientResource.data.name[i]._prefix[j]?.extension) {
                            for (let z = 0; z < newPatientResource.data.name[i]._prefix[j].extension.length; z++) {
                                if (
                                    newPatientResource.data.name[i]._prefix[j].extension[z].url ===
                                        'http://hl7.org/fhir/StructureDefinition/iso21090-EN-qualifier' &&
                                    newPatientResource.data.name[i]._prefix[j].extension[z].valueCode === 'AC'
                                ) {
                                    prefixPosition = j;
                                    break;
                                }
                            }
                        }
                    }

                    newPatientResource.data.name[i].prefix[prefixPosition] = newData.academicTitle;
                } else {
                    if (newPatientResource.data.name[i].prefix !== undefined) {
                        prefixPosition = newPatientResource.data.name[i].prefix.length;
                    }
                    newPatientResource.data.name[i].prefix[prefixPosition] = newData.academicTitle;

                    // newPatientResource.data.name[i]._prefix = [
                    //     {
                    //         extension: [
                    //             {
                    //                 url: 'http://hl7.org/fhir/StructureDefinition/iso21090-EN-qualifier',
                    //                 valueCode: 'AC',
                    //             },
                    //         ],
                    //     },
                    // ];

                    newPatientResource.data.name[i]._prefix = [];
                    for (let j = 0; j <= prefixPosition; j++) {
                        if (prefixPosition === j) {
                            newPatientResource.data.name[i]._prefix[j] = [
                                {
                                    extension: [
                                        {
                                            url: 'http://hl7.org/fhir/StructureDefinition/iso21090-EN-qualifier',
                                            valueCode: 'AC',
                                        },
                                    ],
                                },
                            ];
                        } else {
                            newPatientResource.data.name[i]._prefix[j] = null;
                        }
                    }
                }
            }
        }
    }
    if (newData.street !== undefined || newData.streetNr !== undefined) {
        if (newPatientResource.data.address !== undefined) {
            for (let i = 0; i < newPatientResource.data.address.length; i++) {
                if (newPatientResource.data.address[i].use === 'home') {
                    if (newPatientResource.data.address[i].line !== undefined) {
                        newPatientResource.data.address[i].line[0] = createAddressLine(
                            newData.street,
                            newData.streetNr,
                        );
                    } else {
                        newPatientResource.data.address[i].line = [createAddressLine(newData.street, newData.streetNr)];
                    }
                    if (newPatientResource.data.address[i]._line !== undefined) {
                        if (newPatientResource.data.address[i]._line[0].extension) {
                            let streetNameFound = false;
                            let houseNumberFound = false;
                            for (let j = 0; j < newPatientResource.data.address[i]._line[0].extension.length; j++) {
                                if (
                                    newPatientResource.data.address[i]._line[0].extension[j].url ===
                                    'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-streetName'
                                ) {
                                    if (newData.street !== undefined && newData.street.length > 0) {
                                        newPatientResource.data.address[i]._line[0].extension[j].valueString =
                                            newData.street;
                                    } else {
                                        newPatientResource.data.address[i]._line[0].extension[j].valueString = '';
                                    }
                                    streetNameFound = true;
                                }
                                if (
                                    newPatientResource.data.address[i]._line[0].extension[j].url ===
                                    'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-houseNumber'
                                ) {
                                    if (newData.streetNr !== undefined && newData.streetNr.length > 0) {
                                        newPatientResource.data.address[i]._line[0].extension[j].valueString =
                                            newData.streetNr;
                                    } else {
                                        newPatientResource.data.address[i]._line[0].extension[j].valueString = '';
                                    }
                                    houseNumberFound = true;
                                }
                            }
                            if (!streetNameFound && newData.street !== undefined && newData.street !== '') {
                                newPatientResource.data.address[i]._line[0].extension.push({
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-streetName',
                                    valueString: newData.street,
                                });
                            }
                            if (!houseNumberFound && newData.streetNr !== undefined && newData.streetNr !== '') {
                                newPatientResource.data.address[i]._line[0].extension.push({
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-houseNumber',
                                    valueString: newData.streetNr,
                                });
                            }
                        } else {
                            newPatientResource.data.address[i]._line = [
                                {
                                    extension: [],
                                },
                            ];
                            if (newData.street !== undefined && newData.street !== '') {
                                newPatientResource.data.address[i]._line[0].extension.push({
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-streetName',
                                    valueString: newData.street,
                                });
                            }
                            if (newData.streetNr !== undefined && newData.streetNr !== '') {
                                newPatientResource.data.address[i]._line[0].extension.push({
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-houseNumber',
                                    valueString: newData.streetNr,
                                });
                            }
                        }
                    } else {
                        // add _line with extensions, but add only values in extension if we have a street and/ or streetNr
                        if (
                            (newData.street !== undefined && newData.street.length > 0) ||
                            (newData.streetNr !== undefined && newData.streetNr.length > 0)
                        ) {
                            newPatientResource.data.address[i]._line = [
                                {
                                    extension: [],
                                },
                            ];
                            if (newData.street !== undefined) {
                                newPatientResource.data.address[i]._line[0].extension.push({
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-streetName',
                                    valueString: newData.street,
                                });
                            }
                            if (newData.streetNr !== undefined) {
                                newPatientResource.data.address[i]._line[0].extension.push({
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-houseNumber',
                                    valueString: newData.streetNr,
                                });
                            }
                        }
                    }
                }
            }
        }
    } else {
        if (newData.street !== undefined || newData.streetNr !== undefined) {
            newPatientResource.data.address = [
                {
                    use: 'home',
                    line: [newData.street + ' ' + newData.streetNr],
                    _line: [
                        {
                            extension: [
                                {
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-streetName',
                                    valueString: newData.street,
                                },
                                {
                                    url: 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-houseNumber',
                                    valueString: newData.streetNr,
                                },
                            ],
                        },
                    ],
                },
            ];
            newPatientResource.data.address[0].line[0] = newData.street + ' ' + newData.streetNr;
        }
    }

    if (newData.tel !== undefined) {
        if (newPatientResource.data.telecom !== undefined) {
            let foundPhoneEntry = false;
            for (let i = 0; i < newPatientResource.data.telecom.length; i++) {
                if (
                    newPatientResource.data.telecom[i].system === 'phone' &&
                    newPatientResource.data.telecom[i].use === 'home'
                ) {
                    foundPhoneEntry = true;
                    newPatientResource.data.telecom[i].value = newData.tel;
                    break;
                }
            }
            if (!foundPhoneEntry) {
                newPatientResource.data.telecom[newPatientResource.data.telecom.length] = [
                    {
                        system: 'phone',
                        use: 'home',
                        value: newData.tel,
                    },
                ];
            }
        } else {
            newPatientResource.data.telecom = [
                {
                    system: 'phone',
                    use: 'home',
                    value: newData.tel,
                },
            ];
        }
    }
    if (newData.mobilePhone !== undefined) {
        if (newPatientResource.data.telecom !== undefined) {
            let foundPhoneEntry = false;
            for (let i = 0; i < newPatientResource.data.telecom.length; i++) {
                if (
                    newPatientResource.data.telecom[i].system === 'phone' &&
                    newPatientResource.data.telecom[i].use === 'mobile'
                ) {
                    foundPhoneEntry = true;
                    newPatientResource.data.telecom[i].value = newData.mobilePhone;
                    break;
                }
            }
            if (!foundPhoneEntry) {
                newPatientResource.data.telecom[newPatientResource.data.telecom.length] = [
                    {
                        system: 'phone',
                        use: 'mobile',
                        value: newData.mobilePhone,
                    },
                ];
            }
        } else {
            newPatientResource.data.telecom = [
                {
                    system: 'phone',
                    use: 'mobile',
                    value: newData.mobilePhone,
                },
            ];
        }
    }
    if (newData.zip !== undefined) {
        if (newPatientResource.data.address !== undefined) {
            for (let i = 0; i < newPatientResource.data.address.length; i++) {
                if (newPatientResource.data.address[i].use === 'home') {
                    newPatientResource.data.address[i].postalCode = newData.zip;
                    break;
                }
            }
        } else {
            newPatientResource.data.address = [
                {
                    postalCode: newData.zip,
                    use: 'home',
                },
            ];
        }
    }
    if (newData.plannedStudies !== undefined) {
        updatePatientResourcePlannedStudies(newPatientResource, newData.plannedStudies);
    }

    return newPatientResource;
};

export const getPatientId = (patientdataJson: IPatientResource) => {
    const id = patientdataJson.id;
    if (id !== undefined) {
        return id;
    }
};

export const getPatientNumber = (patientdataJson: IPatientResource) => {
    const ele = patientdataJson.identifier?.find((element) => {
        return element.system?.endsWith('patientNumber');
    });
    if (ele) {
        return ele.value;
    }
};

export const getFamilyName = (patientdataJson: IPatientResource) => {
    const nameArr = patientdataJson.name;
    for (let i = 0; i < nameArr.length; i++) {
        if (nameArr[i].use === 'usual') {
            const familyName = nameArr[i].family;
            if (familyName !== undefined) {
                return familyName;
            }
        }
    }
};

export const getGivenName = (patientdataJson: IPatientResource) => {
    const nameArr = patientdataJson.name;
    for (let i = 0; i < nameArr.length; i++) {
        if (nameArr[i].use === 'usual') {
            const givenName = nameArr[i].given;
            if (givenName !== undefined) {
                return givenName[0];
            }
        }
    }
};

export const getInsuranceNr = (patientdataJson: IPatientResource) => {
    const identifier = patientdataJson.identifier;

    if (identifier !== undefined) {
        for (let i = 0; i < identifier.length; i++) {
            if (identifier[i].system === 'http://fhir.de/NamingSystem/gkv/kvid-10') {
                const insuranceNr = identifier[i].value;
                if (typeof insuranceNr !== 'undefined') {
                    return insuranceNr;
                }
            }
        }
    }
};

export const getOrganizationName = (patientdataJson: IPatientResource) => {
    const orga = patientdataJson.managingOrganization;

    if (orga !== undefined) {
        if (orga.display) {
            return orga.display;
        }
    }
};

export const getBirthDate = (patientdataJson: IPatientResource) => {
    const birthdate = patientdataJson.birthDate;
    return getBirthdayPrettyFormatedWithAge(birthdate);
};

export const getGender = (patientdataJson: IPatientResource) => {
    const gender = patientdataJson.gender;
    if (gender !== undefined) {
        return gender;
    }
};

export const getHomePhone = (patientdataJson: IPatientResource) => {
    const telecomArr = patientdataJson.telecom;

    if (telecomArr !== undefined) {
        for (let i = 0; i < telecomArr.length; i++) {
            if (telecomArr[i].system === 'phone' && telecomArr[i].use === 'home') {
                const phone = telecomArr[i].value;
                if (typeof phone !== 'undefined') {
                    return phone;
                }
            }
        }
    }
};

export const getMobilePhone = (patientdataJson: IPatientResource) => {
    const telecomArr = patientdataJson.telecom;

    if (telecomArr !== undefined) {
        for (let i = 0; i < telecomArr.length; i++) {
            if (telecomArr[i].system === 'phone' && telecomArr[i].use === 'mobile') {
                const phone = telecomArr[i].value;
                if (typeof phone !== 'undefined') {
                    return phone;
                }
            }
        }
    }
};

export const getAcademicTitle = (patientdataJson: IPatientResource) => {
    const nameArr = patientdataJson.name;

    let arrayPosition = -1;

    if (nameArr) {
        for (let i = 0; i < nameArr.length; i++) {
            if (nameArr[i]._prefix) {
                for (let j = 0; j < nameArr[i]._prefix.length; j++) {
                    if (nameArr[i]._prefix[j]) {
                        if (nameArr[i]._prefix[j].extension) {
                            for (let z = 0; z < nameArr[i]._prefix[j].extension.length; z++) {
                                if (
                                    nameArr[i]._prefix[j].extension[z].url ===
                                        'http://hl7.org/fhir/StructureDefinition/iso21090-EN-qualifier' &&
                                    nameArr[i]._prefix[j].extension[z].valueCode === 'AC'
                                ) {
                                    arrayPosition = j;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (arrayPosition >= 0) {
        for (let i = 0; i < nameArr.length; i++) {
            const value = nameArr[i].prefix[arrayPosition];
            if (value) {
                return value;
            }
        }
    }
};

export const getCountry = (patientdataJson: IPatientResource) => {
    const addressArr = patientdataJson.address;

    if (addressArr !== undefined) {
        for (let i = 0; i < addressArr.length; i++) {
            if (addressArr[i].use === 'home') {
                const country = addressArr[i].country;
                if (typeof country !== 'undefined') {
                    const countryLongName = getCountryLongName(country);
                    return countryLongName;
                }
            }
        }
    }
};

export const getHomeEmail = (patientdataJson: IPatientResource) => {
    const telecomArr = patientdataJson.telecom;

    if (telecomArr !== undefined) {
        for (let i = 0; i < telecomArr.length; i++) {
            if (telecomArr[i].system === 'email' && telecomArr[i].use === 'home') {
                const email = telecomArr[i].value;
                if (typeof email !== 'undefined') {
                    return email;
                }
            }
        }
    }
};

export const getHomeCity = (patientdataJson: IPatientResource) => {
    const addressArr = patientdataJson.address;

    if (addressArr !== undefined) {
        for (let i = 0; i < addressArr.length; i++) {
            if (addressArr[i].use === 'home') {
                const city = addressArr[i].city;
                if (typeof city !== 'undefined') {
                    return city;
                }
            }
        }
    }
};

export const getHomeStreet = (patientdataJson: IPatientResource) => {
    // const addressLine = splitAdressLineToStreetAndHouseNr(patientdataJson);
    // return addressLine.street;

    const addressArr = patientdataJson.address;

    if (addressArr !== undefined) {
        for (let i = 0; i < addressArr.length; i++) {
            if (addressArr[i].use === 'home' && addressArr[i]._line) {
                const lineExtensions = addressArr[i]._line[0].extension;
                for (let j = 0; j < lineExtensions.length; j++) {
                    if (lineExtensions[j].url === 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-streetName') {
                        return lineExtensions[j].valueString;
                    }
                }
            }
        }
    }
    return undefined;
};

export const getHomeHouseNr = (patientdataJson: IPatientResource) => {
    // const addressLine = splitAdressLineToStreetAndHouseNr(patientdataJson);
    // return addressLine.houseNr;

    const addressArr = patientdataJson.address;

    if (addressArr !== undefined) {
        for (let i = 0; i < addressArr.length; i++) {
            if (addressArr[i].use === 'home' && addressArr[i]._line) {
                const lineExtensions = addressArr[i]._line[0].extension;
                for (let j = 0; j < lineExtensions.length; j++) {
                    if (lineExtensions[j].url === 'http://hl7.org/fhir/StructureDefinition/iso21090-ADXP-houseNumber') {
                        return lineExtensions[j].valueString;
                    }
                }
            }
        }
    }
    return undefined;
};

// export const splitAdressLineToStreetAndHouseNr = (patientdataJson: IPatientResource): IPatientResourceAddress => {
//     const addressLine = getAddressLine(patientdataJson);
//     let firstNumberAppearance = 0;

//     const address: IPatientResourceAddress = {
//         street: '',
//         houseNr: '',
//     };

//     if (addressLine) {
//         for (let i = 0; i < addressLine.length; i++) {
//             const character = addressLine.charAt(i);
//             if (character >= '0' && character <= '9') {
//                 firstNumberAppearance = i;
//                 break;
//             }
//         }

//         let street = '';
//         let houseNr = '';
//         if (firstNumberAppearance === 0) {
//             street = addressLine.trim();
//         } else {
//             street = addressLine.substring(0, firstNumberAppearance).trim();
//             houseNr = addressLine.substring(firstNumberAppearance, addressLine.length).trim();
//         }

//         address.street = street;
//         address.houseNr = houseNr;
//     }

//     return address;
// };

// /**
//  * returns street and houseNr in one line -> split it in getHomeStreet and getHomeHouseNr
//  *
//  * @param patientdataJson the response from getPatientResource
//  */
// export const getAddressLine = (patientdataJson: IPatientResource) => {
//     const addressArr = patientdataJson.address;

//     if (addressArr !== undefined) {
//         for (let i = 0; i < addressArr.length; i++) {
//             if (addressArr[i].use === 'home') {
//                 const lineArr = addressArr[i].line;
//                 if (typeof lineArr !== 'undefined') {
//                     return lineArr[0];
//                 }
//             }
//         }
//     }
// };

export const getHomePostalCode = (patientdataJson: IPatientResource) => {
    const addressArr = patientdataJson.address;

    if (addressArr !== undefined) {
        for (let i = 0; i < addressArr.length; i++) {
            if (addressArr[i].use === 'home') {
                const postalCode = addressArr[i].postalCode;
                if (typeof postalCode !== 'undefined') {
                    return postalCode;
                }
            }
        }
    }
};

export const getLanguage = (patientdataJson: IPatientResource) => {
    if (patientdataJson.communication !== undefined) {
        const communcationArr = patientdataJson.communication;
        for (let i = 0; i < communcationArr.length; i++) {
            if (communcationArr[i].language !== undefined) {
                const language = communcationArr[i].language;
                if (language !== undefined) {
                    if (language.coding !== undefined) {
                        const languageName = language.coding[0].display;
                        return languageName;
                    }
                }
            }
        }
    }
};

export const getInsuranceName = (patientdataJson: IPatientResource) => {
    if (patientdataJson.extension !== undefined) {
        const communcationArr = patientdataJson.communication;
        if (communcationArr !== undefined) {
            for (let i = 0; i < communcationArr.length; i++) {
                if (communcationArr[i].language !== undefined) {
                    const language = communcationArr[i].language;
                    if (language !== undefined) {
                        if (language.coding !== undefined) {
                            const languageName = language.coding[0].display;
                            return languageName;
                        }
                    }
                }
            }
        }
    }
};

export const getPlannedStudies = (patientdataJson: IPatientResource) => {
    const allPlannedStudies: IPlannedStudies[] = [];

    if (patientdataJson.extension) {
        const plannedStudies = patientdataJson.extension.filter((p: any) => p.url.endsWith('plannedStudies'));

        if (plannedStudies) {
            plannedStudies.forEach((plannedStudy) => {
                let code = '';
                let display = '';
                let participate = false;

                if (plannedStudy.valueCoding) {
                    code = plannedStudy.valueCoding.code;
                    display = plannedStudy.valueCoding.display;

                    if (plannedStudy.valueCoding.extension) {
                        const plannedStudySelection = plannedStudy.valueCoding.extension.find((p: any) =>
                            p.url.endsWith('plannedStudySelection'),
                        );
                        if (plannedStudySelection) {
                            if (plannedStudySelection.valueBoolean) {
                                participate = plannedStudySelection.valueBoolean;

                                allPlannedStudies.push({
                                    code: code,
                                    display: display,
                                    participate: participate,
                                });
                            }
                        }
                    }
                }
            });
        }
    }

    return allPlannedStudies;
};

export const extractPatData = (patdata: any) => {
    const birthdayFormated = getBirthDate(patdata.data);

    const patdataExt = {
        insuranceNr: getInsuranceNr(patdata.data),
        firstName: getGivenName(patdata.data),
        lastName: getFamilyName(patdata.data),
        birthday: patdata.data.birthDate,
        birthdayFormated: birthdayFormated ? birthdayFormated : '',
        gender: getGender(patdata.data),
        emailHome: getHomeEmail(patdata.data),
        telephoneHome: getHomePhone(patdata.data),
        streetHome: getHomeStreet(patdata.data),
        houseNrHome: getHomeHouseNr(patdata.data),
        cityHome: getHomeCity(patdata.data),
        zipHome: getHomePostalCode(patdata.data),
        country: getCountry(patdata.data),
        mobilePhone: getMobilePhone(patdata.data),
        academicTitle: getAcademicTitle(patdata.data),
        plannedStudies: getPlannedStudies(patdata.data),
        patientId: getPatientId(patdata.data),
        patientNumber: getPatientNumber(patdata.data),
        insuranceName: getInsuranceName(patdata.data),
        language: getLanguage(patdata.data),
        organization: getOrganizationName(patdata.data),
    };

    return patdataExt;
};

export const getPatientProfilePictureWL = async (sessionId: string, userId: string) => {
    return await fhir(getFHIRConfig(sessionId)).search({
        type: 'Patient',
        query: {
            _id: userId,
            _includePicture: 'true',
        },
    });
};

export const getPatientProfilePicture = async (state: InitialLoginType, alertdispatch: React.Dispatch<any>) => {
    if (state.practitionerRoleId) {
        return await fhir(getFHIRConfig(state.sessionId))
            .search({
                type: 'Practitioner',
                query: {
                    _id: state.practitionerRoleId,
                    _includePicture: 'true',
                },
            })
            .catch(function (e: any) {
                responseErrorHandling({
                    response: e,
                    alertdispatch: alertdispatch,
                });
            });
    } else {
        return await fhir(getFHIRConfig(state.sessionId))
            .search({
                type: 'Patient',
                query: {
                    _id: state.userId,
                    _includePicture: 'true',
                },
            })
            .catch(function (e: any) {
                responseErrorHandling({
                    response: e,
                    alertdispatch: alertdispatch,
                });
            });
    }
};

export const getPatientsWithName = async (
    state: InitialLoginType,
    familyName: string,
    givenName: string,
    alertdispatch: React.Dispatch<any>,
) => {
    return await fhir(getFHIRConfig(state.sessionId))
        .search({
            type: 'Patient',
            query: {
                family: familyName,
                given: givenName,
                _includePicture: 'true',
                practitioner: state.practitionerRoleId,
                _count: 30,
            },
        })
        .catch(function (e: any) {
            responseErrorHandling({
                response: e,
                alertdispatch: alertdispatch,
            });
        });
};
