import { createContext } from 'react';
import { ILink } from './loginContext';

export interface IRegistrationFields {
    birthDate: boolean;
    city: boolean;
    country: boolean;
    email: boolean;
    firstName: boolean;
    gender: boolean;
    lastName: boolean;
    phone: boolean;
    street: boolean;
    zip: boolean;
    insuranceNr: boolean;
    mobilePhone: boolean;
    academicTitle: boolean;
    insuranceName: boolean;
    language: boolean;
    privacyTerms: boolean;
}

export interface IAppointmentSummary {
    behandler: boolean;
    fach: boolean;
    pflege: boolean;
    raum: boolean;
    standort: boolean;
}

export interface IAppointmentGeneral {
    skipBehandlersuche?: boolean;
}

export interface ICustomTexts {
    CredentialsLost: string;
}

export interface IImpressum {
    impressumLink: string;
    datenschutzLink: string;
}

export interface IAnmeldeContexts {
    code: string;
    name: string;
}

export type InitialAppPropsType = {
    appPropsSet: boolean;
    Appointment?: IAppointmentGeneral;
    AppointmentSummary: IAppointmentSummary;
    RegistrationMandFields: IRegistrationFields;
    RegistrationVisibleFields: IRegistrationFields;
    RegistrationReadOnlyFields: IRegistrationFields;
    allowAccountAfterAppointment: boolean;
    allowAccountCreateDirect: boolean;
    allowAppointments: boolean;
    allowAppointmentsEinweiser: boolean;
    allowAppointmentsWithoutLogin: boolean;
    allowDirectAppointments: boolean;
    allowDirectAppointmentsWithoutLogin: boolean;
    allowLogin: boolean;
    allowVideo: boolean;
    allowVideoWithoutLogin: boolean;
    allowAccountInAnmeldeworkflow: boolean;
    allowContactPerson: boolean;
    brandingBytes: string;
    brandigType: string;
    extraLinks: Array<ILink>;
    customTexts: ICustomTexts;
    showMobileQR: boolean;
    jitsiDomain: string;
    jitsiOptions: any;
    impressum: IImpressum;
    anmeldeContexts: Array<IAnmeldeContexts>;
    countDaysToVisualize?: number;
    extendedPhysicians: boolean;
    allowChat: boolean;
    fullscreenMode: boolean;
    googleAnalyticsID?: string;
    tileTexts?: any;
    smtpUser?: string;
    mailFromAddress?: string;
    possibleCountries: string;
    availableLanguages: string;
    disableTerms: boolean;
    cbxShowAboutbutton: boolean;
    cbxShowPIDEinweiser: boolean;
    cbxShowPatAddrEinweiser: boolean;
    cbxShowPatOrganization: boolean;
};

export const InitialAppPropsState = {
    appPropsSet: false,
    Appointment: {
        skipBehandlersuche: false,
    },
    AppointmentSummary: {
        behandler: true,
        fach: true,
        pflege: true,
        raum: true,
        standort: true,
    },
    RegistrationMandFields: {
        birthDate: false,
        city: false,
        country: false,
        email: true,
        firstName: true,
        gender: false,
        lastName: true,
        phone: false,
        street: false,
        zip: false,
        insuranceNr: false,
        mobilePhone: false,
        academicTitle: false,
        insuranceName: false,
        language: false,
        privacyTerms: false,
    },
    RegistrationVisibleFields: {
        birthDate: true,
        city: true,
        country: true,
        email: true,
        firstName: true,
        gender: true,
        lastName: true,
        phone: true,
        street: true,
        zip: true,
        insuranceNr: false,
        mobilePhone: true,
        academicTitle: true,
        insuranceName: true,
        language: true,
        privacyTerms: false,
    },
    RegistrationReadOnlyFields: {
        birthDate: false,
        city: false,
        country: false,
        email: false,
        firstName: false,
        gender: false,
        lastName: false,
        phone: false,
        street: false,
        zip: false,
        insuranceNr: false,
        mobilePhone: false,
        academicTitle: false,
        insuranceName: false,
        language: false,
        privacyTerms: false,
    },
    allowAccountAfterAppointment: false,
    allowAccountCreateDirect: false,
    allowAppointments: false,
    allowAppointmentsEinweiser: false,
    allowAppointmentsWithoutLogin: false,
    allowDirectAppointments: false,
    allowDirectAppointmentsWithoutLogin: false,
    allowLogin: false,
    allowVideo: false,
    allowVideoWithoutLogin: false,
    allowAccountInAnmeldeworkflow: true,
    allowContactPerson: false,
    brandingBytes: '',
    brandigType: '',
    extraLinks: [],
    customTexts: {
        CredentialsLost: '',
    },
    showMobileQR: true,
    jitsiDomain: '',
    jitsiOptions: null,
    impressum: {
        impressumLink: '',
        datenschutzLink: '',
    },
    anmeldeContexts: [],
    countDaysToVisualize: 0,
    extendedPhysicians: false,
    allowChat: false,
    fullscreenMode: false,
    googleAnalyticsID: '',
    tileTexts: {},
    smtpUser: '',
    mailFromAddress: '',
    possibleCountries: '',
    availableLanguages: 'de',
    disableTerms: false,
    cbxShowAboutbutton: true,
    cbxShowPIDEinweiser: false,
    cbxShowPatAddrEinweiser: false,
    cbxShowPatOrganization: true,
};

const AppPropsContext = createContext<{
    apstate: InitialAppPropsType;
    apdispatch: React.Dispatch<any>;
}>({
    apstate: InitialAppPropsState,
    apdispatch: () => null,
});

export default AppPropsContext;
