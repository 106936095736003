import { getAppointmentChatEntry, IAppointmentChat, IIncomingMessage } from '../components/Chat/Chat';
import { InitialVideochatType } from '../context/videochatContext';

type Action =
    | {
          type: 'ONMESSAGE';
          incomingCall: boolean;
          callAccepted: boolean;
          caller: string;
          remoteSid: string;
          roomName: string;
          iceServer: Array<string>;
          mySid: string;
      }
    | {
          type: 'ONACCEPT';
          incomingCall: boolean;
          callAccepted: boolean;
          caller: string;
          remoteSid: string;
          iceServer: Array<string>;
      }
    | {
          type: 'ONDISCONNECT';
          incomingCall: boolean;
          callAccepted: boolean;
          caller: string;
          remoteSid: string;
          roomName: string;
          iceServer: Array<string>;
          chatMessages: IIncomingMessage[];
          appointmentChat: [];
          lastIncomingAppChatMsg: {
              id: '';
              incomingMessages: [];
          };
      }
    | {
          type: 'ONLOCALSTREAM';
          localStream: any;
          iceServer: Array<string>;
      }
    | {
          type: 'ONREMOTESTREAM';
          remoteStream: any;
          iceServer: Array<string>;
      }
    | {
          type: 'SETICESERVER';
          iceServer: Array<string>;
      }
    | {
          type: 'SETCHATMESSAGES';
          chatMessages: IIncomingMessage[];
      }
    | {
          type: 'RESETCHATMESSAGES';
      }
    | {
          type: 'SETLASTINCOMINGTEXTCHATMSG';
          lastIncomingTextChatMsg: string;
      }
    | {
          type: 'SETAPPOINTMENTCHAT';
          appointmentChat: IAppointmentChat[];
      }
    | {
          type: 'CLOSEALLAPPOINTMENTCHATS';
      }
    | {
          type: 'SETLASTINCOMINGCHATMESSAGES';
          lastIncomingAppChatMsg: {
              id: string;
              incomingMessages: [];
          };
      }
    | {
          type: 'SETNUMBERUNREADMESSAGES';
          numberUnreadMessages: number;
      }
    | {
          type: 'RESETNUMBERUNREADMESSAGES';
      }
    | {
          type: 'INCREASENUMBERUNREADMESSAGES';
      }
    | {
          type: 'SETAUDIOMUTED';
          audioMuted: boolean;
      }
    | {
          type: 'SETJITSIALLOWED';
          jitsiAllowed: boolean;
          roomName: string;
      }
    | {
          type: 'JITSIENROLLERROR';
          jitsiError: string;
      };

export const videochatReducer = (state: InitialVideochatType, action: Action) => {
    switch (action.type) {
        case 'ONMESSAGE':
            return {
                ...state,
                incomingCall: true,
                callAccepted: false,
                caller: action.caller,
                remoteSid: action.remoteSid,
                roomName: action.roomName,
                mySid: action.mySid,
            };

        case 'ONACCEPT':
            return {
                ...state,
                incomingCall: false,
                callAccepted: true,
                caller: action.caller,
                remoteSid: action.remoteSid,
                jitsiAllowed: true,
            };

        case 'ONDISCONNECT':
            if (window.app.wsconnect && window.app.wsconnect.readyState == 1) {
                if (window.app.wsconnect.peerConnection) {
                    window.app.wsconnect.peerConnection.close();
                }
                window.app.wsconnect.close();
                window.app.wsconnect = null;
                if (state.localStream) {
                    state.localStream.getTracks().forEach(function (track: any) {
                        track.stop();
                    });
                    delete state.localStream;
                }
                if (state.remoteStream) {
                    state.remoteStream.getTracks().forEach(function (track: any) {
                        track.stop();
                    });
                    delete state.remoteStream;
                }
            }
            return {
                ...state,
                incomingCall: false,
                callAccepted: false,
                caller: '',
                remoteSid: '',
                roomName: '',
                chatMessages: [],
                numberUnreadMessages: 0,
                appointmentChat: [],
                lastIncomingAppChatMsg: {
                    id: '',
                    incomingMessages: [],
                },
                jitsiAllowed: false,
            };

        case 'ONLOCALSTREAM':
            return {
                ...state,
                localStream: action.localStream,
            };
        case 'ONREMOTESTREAM':
            return {
                ...state,
                remoteStream: action.remoteStream,
            };
        case 'SETICESERVER':
            return {
                ...state,
                iceServer: action.iceServer,
            };
        case 'SETCHATMESSAGES':
            return {
                ...state,
                chatMessages: action.chatMessages,
            };
        case 'RESETCHATMESSAGES':
            return {
                ...state,
                chatMessages: [],
                lastIncomingTextChatMsg: '',
            };
        case 'SETLASTINCOMINGTEXTCHATMSG':
            return {
                ...state,
                lastIncomingTextChatMsg: action.lastIncomingTextChatMsg,
            };
        case 'SETAPPOINTMENTCHAT':
            return {
                ...state,
                appointmentChat: action.appointmentChat,
            };
        case 'CLOSEALLAPPOINTMENTCHATS':
            const appChat = state.appointmentChat.map((item) => {
                item.chatOpen = false;
                return item;
            });

            return {
                ...state,
                appointmentChat: appChat,
            };
        case 'SETLASTINCOMINGCHATMESSAGES':
            let appointmentChat;
            const appointmentChatEntry = getAppointmentChatEntry(
                state.appointmentChat,
                action.lastIncomingAppChatMsg.id,
            );
            if (appointmentChatEntry) {
                /* nothing changed, there are no new messages, so do nothing...*/
                if (
                    appointmentChatEntry.incomingMessages?.length ===
                    action.lastIncomingAppChatMsg.incomingMessages.length
                ) {
                    return {
                        ...state,
                    };
                }
                appointmentChat = state.appointmentChat.map((item: IAppointmentChat) => {
                    if (item.id === action.lastIncomingAppChatMsg.id) {
                        item.incomingMessages = action.lastIncomingAppChatMsg.incomingMessages;
                        if (item.numberUnreadMsg) {
                            item.numberUnreadMsg = item.numberUnreadMsg + 1;
                        } else {
                            item.numberUnreadMsg = 1;
                        }
                    }
                    return item;
                });

                return {
                    ...state,
                    appointmentChat: appointmentChat,
                };
            } else {
                const appChatValue: IAppointmentChat = {
                    id: action.lastIncomingAppChatMsg.id,
                    chatOpen: false,
                    incomingMessages: action.lastIncomingAppChatMsg.incomingMessages,
                    numberUnreadMsg: 1,
                };
                const newAppointmentChat = state.appointmentChat;
                newAppointmentChat.push(appChatValue);
                let numberUnread = 0;
                if (state.numberUnreadMessages) {
                    numberUnread = state.numberUnreadMessages + 1;
                } else {
                    numberUnread = 1;
                }
                return {
                    ...state,
                    appointmentChat: newAppointmentChat,
                    numberUnreadMessages: numberUnread,
                };
            }
        case 'SETNUMBERUNREADMESSAGES':
            return {
                ...state,
                numberUnreadMessages: action.numberUnreadMessages,
            };
        case 'RESETNUMBERUNREADMESSAGES':
            return {
                ...state,
                numberUnreadMessages: 0,
            };
        case 'INCREASENUMBERUNREADMESSAGES':
            return {
                ...state,
                numberUnreadMessages: state.numberUnreadMessages + 1,
            };
        case 'SETAUDIOMUTED':
            return {
                ...state,
                audioMuted: action.audioMuted,
            };
        case 'SETJITSIALLOWED':
            return {
                ...state,
                jitsiAllowed: action.jitsiAllowed,
                roomName: action.roomName,
            };
        case 'JITSIENROLLERROR':
            return {
                ...state,
                jitsiError: action.jitsiError,
            };
        default:
            return state;
    }
};
