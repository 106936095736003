import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import produce from 'immer';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/Row';
import Lottie from 'react-lottie';
import { useLocation, useNavigate } from 'react-router-dom';
import { isTimeslotDateSet } from '../../components/Calendar/TimeSlots';
import AppointmentCategoryExtendedPhysician from '../../container/AppointmentCategory/AppointmentCategoryExtendedPhysican';
import { ITermineContent } from '../../container/Termine/Termine';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { appointmentStatus } from '../../globals/global';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { Paths } from '../../Routes';
import { bookAppointment, bookMultiAppointment } from '../../services/fhir/FHIRAppointment';
import { blockAppointment, unblockAppointment } from '../../services/RestServices';
import {
    APPOINTMENTTYPE_CAREUNIT,
    APPOINTMENTTYPE_DEPARTMENT,
    APPOINTMENTTYPE_PRACTITIONER,
    APPOINTMENTTYPE_ROOM,
    getAPKNrFromExtension,
    getCountAPKsFromExtension,
    getEnrollCodeFromExtension,
    getLocationCareUnitNameFromResource,
    getLocationDepartmentNameFromResource,
    getLocationRoomNameFromResource,
    getMissingAPKsFromExtension,
    getPractitionerNameFromResource,
    getSelectedReasonDataFromAppointment,
    IAppointment,
    IParticipant,
} from '../../utils/appointmentUtils';
import { lottieCheck } from '../../utils/lottieUtils';
import { getCurrentBaseURL, getTermincodeEnrollIdURL } from '../../utils/urlUtils';
import AppointmentCalendarWithHeadline from '../AppointmentCalendar/AppointmentCalendarWithHeadline';
import Booking from '../Booking/Booking';
import StyledButton from '../Buttons/StyledButton';
import { onNewAppointments } from '../Calendar/Calendar';
import AppointmentCard from '../Cards/AppointmentCard';
import StyledCol from '../Cols/StyledCol';
import { CenterDiv, StepperContentDiv, StickyButtonRowBottomDiv } from '../Div/StyledDiv';
import BlockingAppointmentModal from '../Modals/BlockingAppointmentModal/BlockingAppointmentModal';
import { StyledRowNoMargin } from '../Rows/StyledRow';
import './Stepper.css';
import { showAlertMessage } from '../../utils/alertHandling';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';

interface IBlockingModalShow {
    show: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        iconContainer: {
            transform: 'scale(1.5)',
        },
    }),
);

const STEP_KATEGORIE_WAEHLEN = 'Kategorie wählen';
const STEP_TERMIN_WAEHLEN = 'Termin wählen';
const STEP_TERMIN_BUCHEN = 'Termin buchen';

function isStepMultiAppointment(stepName: string) {
    const match = stepName.match(/Termin [0-9]$/);
    return match;
}

function getCurrentAppointmentNr(stepName: string) {
    if (isStepMultiAppointment(stepName)) {
        const numberStr = stepName.charAt(stepName.length - 1);
        return parseInt(numberStr);
    } else {
        return 1;
    }
}

function StepperTerminierungAlternateDate(props: ITermineContent) {
    const navigate = useNavigate();
    const { state } = useContext(LoginContext);

    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [steps, setSteps] = useState([STEP_KATEGORIE_WAEHLEN, STEP_TERMIN_WAEHLEN, STEP_TERMIN_BUCHEN]);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
    const [bookSuccess, setBookSuccess] = useState(false);
    const [enrollCode, setEnrollCode] = useState('');
    const [appointmentCardData, setAppointmentCardData] = useState<any>();
    const [appointmentCardData_2, setAppointmentCardData_2] = useState<any>();
    const [blockingModalShow, setBlockingModalShow] = useState<IBlockingModalShow>({
        show: false,
    });
    const [appointmentBooked_1, setAppointmentBooked_1] = useState(false);
    const [appointmentBooked_2, setAppointmentBooked_2] = useState(false);
    const { alertdispatch } = useContext(AlertContext);

    const {
        setSelectedResource,
        setSelectedTimeslot,
        getSelectedResource,
        getSelectedTimeslot,
        resetAllSelectedTimeslots,
        resetAllAvailableTimeslots,
        resetAllSelectedResource,
        isAppointmentPostpone,
        cancelPostponeAppointment,
    } = useAppointmentSelection();

    const location = useLocation();

    function getStepContent(steps: string[], stepIndex: number) {
        const stepName = steps[stepIndex];

        if (stepName === STEP_KATEGORIE_WAEHLEN) {
            return <></>;
        } else if (isStepMultiAppointment(stepName)) {
            return (
                <AppointmentCalendarWithHeadline
                    terminName={stepName}
                    handleTimelotClick={handleTimeslotClick}
                    currentActiveStep={2}
                />
            );
        } else if (stepName === STEP_TERMIN_BUCHEN) {
            return <Booking />;
        } else if (stepName === STEP_TERMIN_WAEHLEN) {
            if (apstate.extendedPhysicians) {
                return (
                    <AppointmentCategoryExtendedPhysician
                        department={tmstate.selectedDepartment.name}
                        reason={tmstate.selectedReason}
                        handleTimeslotClick={handleTimeslotClick}
                        // newAccountHashParam={props.newAccountHashParams}
                        currentActiveStep={2}
                        showSummaryBadgeHeader={false}
                    />
                );
            }
            return (
                <AppointmentCalendarWithHeadline
                    terminName={'Termin'}
                    handleTimelotClick={handleTimeslotClick}
                    currentActiveStep={2}
                />
            );
        } else {
            return 'Unknown step';
        }
    }

    const handleTimeslotClick = (currentActiveStep: number) => {
        if (apstate.extendedPhysicians) {
            // setActiveStep(currentActiveStep);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            setNextButtonEnabled(true);
        }
    };

    const handleNext = async (e: React.MouseEvent<HTMLButtonElement> | null) => {
        if (e) {
            e.currentTarget.blur();
        }

        let gotNewAppointment = false;

        if (activeStep === steps.length - 2) {
            setNextButtonEnabled(true); //final step should always be enabled
        } else {
            setNextButtonEnabled(false);
        }

        if (isStepMultiAppointment(steps[activeStep])) {
            const appointmentNr = getCurrentAppointmentNr(steps[activeStep]);

            /* blockAppointment */
            const resource = getSelectedResource(appointmentNr);

            if (resource) {
                const blockResponse = await blockAppointment(resource);
                if (blockResponse.success === 'false') {
                    onNewAppointments(blockResponse.newApp, appointmentNr, setSelectedTimeslot);
                    gotNewAppointment = true;
                    setBlockingModalShow({
                        show: true,
                    });
                } else {
                    /* nextAppointment */
                    tmdispatch({
                        type: 'WORKONAPPOINTMENTNR',
                        workOnAppointmentNr: appointmentNr + 1,
                    });
                }
            }
        }

        if (steps[activeStep] === STEP_TERMIN_BUCHEN) {
            let multiAppointment = false;
            const selectedTimeslot = getSelectedTimeslot(1);
            let selectedResource: any = getSelectedResource(1);

            const selectedTimeslot_2 = getSelectedTimeslot(2);
            let selectedResource_2: any = getSelectedResource(2);

            if (isTimeslotDateSet(selectedTimeslot)) {
                selectedResource = produce(selectedResource, (draft: any) => {
                    draft.start = selectedTimeslot?.start;
                    draft.end = selectedTimeslot?.end;
                    draft.extension = [];
                    if (tmstate.selectedReason) {
                        draft.extension.push({
                            url: 'http://www.principa.siegele-software.com/selectedReason',
                            valueString: JSON.stringify(tmstate.selectedReason),
                        });
                    }
                    if (!isTimeslotDateSet(selectedTimeslot_2) && tmstate.cancelNewAppointmentData?.data?.id) {
                        draft.extension.push({
                            url: 'http://www.principa.siegele-software.com/originalAppointmentId',
                            valueString: tmstate.cancelNewAppointmentData?.data?.id,
                        });
                    }
                });
            }
            let id = null;
            if (isTimeslotDateSet(selectedTimeslot_2)) {
                selectedResource_2 = produce(selectedResource_2, (draft: any) => {
                    draft.start = selectedTimeslot_2?.start;
                    draft.end = selectedTimeslot_2?.end;
                });
                multiAppointment = true;
            } else {
                id = tmstate.cancelNewAppointmentData?.data?.id;
            }

            let response;
            if (multiAppointment) {
                response = await bookMultiAppointment(
                    state,
                    selectedResource,
                    selectedResource_2,
                    tmstate.remark,
                    tmstate.cancelNewAppointmentData,
                );
            } else {
                response = await bookAppointment(
                    state.sessionId,
                    selectedResource,
                    tmstate.remark,
                    tmstate.earlierAppointmentWanted,
                    id,
                );
            }

            if (!response || response.error) {
                // console.log('problem to book appointment');
                if (response.error === 'Server Error') {
                    setBookSuccess(false);
                } else {
                    setBookSuccess(false);
                    showAlertMessage({
                        alertTitle: 'Terminbuchung',
                        alertTxt: response?.error ? response.error : 'Fehler in der Terminbuchung',
                        alertType: AlertType.error,
                        onHide: AlertOnHide.onlyClose,
                        alertdispatch: alertdispatch,
                    });
                }
            } else {
                setBookSuccess(true);
                setEnrollCode(response.enrollCode);
                // setEnrollCode('ABC123');

                const participantData: IParticipant[] = [
                    {
                        actor: {
                            display: getLocationDepartmentNameFromResource(selectedResource),
                            reference: APPOINTMENTTYPE_DEPARTMENT + '/',
                        },
                        status: 'accepted',
                    },
                    {
                        actor: {
                            display: getLocationCareUnitNameFromResource(selectedResource),
                            reference: APPOINTMENTTYPE_CAREUNIT + '/',
                        },
                        status: 'accepted',
                    },
                    {
                        actor: {
                            display: getLocationRoomNameFromResource(selectedResource),
                            reference: APPOINTMENTTYPE_ROOM + '/',
                        },
                        status: 'accepted',
                    },
                    {
                        actor: {
                            display: getPractitionerNameFromResource(selectedResource),
                            reference: APPOINTMENTTYPE_PRACTITIONER + '/',
                        },
                        status: 'accepted',
                    },
                ];

                const appointmentCardData: IAppointment = {
                    id: '',
                    start: selectedTimeslot?.start,
                    end: selectedTimeslot?.end,
                    status: 'geplant',
                    resource: 'Appointment',
                    participant: participantData,
                    comment: '',
                    type: 'upcoming',
                    isCancelable: false,
                    isInError: false,
                };

                setAppointmentCardData(appointmentCardData);

                let participantData_2;
                let appointmentCardData_2;
                if (selectedResource_2) {
                    participantData_2 = [
                        {
                            actor: {
                                display: getLocationDepartmentNameFromResource(selectedResource_2),
                                reference: APPOINTMENTTYPE_DEPARTMENT + '/',
                            },
                            status: 'accepted',
                        },
                        {
                            actor: {
                                display: getLocationCareUnitNameFromResource(selectedResource_2),
                                reference: APPOINTMENTTYPE_CAREUNIT + '/',
                            },
                            status: 'accepted',
                        },
                        {
                            actor: {
                                display: getLocationRoomNameFromResource(selectedResource_2),
                                reference: APPOINTMENTTYPE_ROOM + '/',
                            },
                            status: 'accepted',
                        },
                        {
                            actor: {
                                display: getPractitionerNameFromResource(selectedResource_2),
                                reference: APPOINTMENTTYPE_PRACTITIONER + '/',
                            },
                            status: 'accepted',
                        },
                    ];

                    appointmentCardData_2 = {
                        id: '',
                        start: selectedTimeslot_2?.start,
                        end: selectedTimeslot_2?.end,
                        status: 'geplant',
                        resource: 'Appointment',
                        participant: participantData_2,
                        comment: '',
                        type: 'upcoming',
                        isCancelable: false,
                    };

                    setAppointmentCardData_2(appointmentCardData_2);
                }

                if (isAppointmentPostpone()) {
                    // cancelPostponeAppointment();
                }
            }
            setNextButtonEnabled(true);
        }

        if (gotNewAppointment) {
            /* We got new appointments due blocking. Only enable next button, but don't go to next step. */
            setNextButtonEnabled(true);
        } else {
            if (appointmentBooked_2) {
                if (steps[activeStep] === 'Termin 1') {
                    setNextButtonEnabled(true);
                    setActiveStep((prevActiveStep) => prevActiveStep + 2);
                } else {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const handleBack = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }

        setNextButtonEnabled(true);

        // if (isStepMultiAppointment(steps[activeStep])) {
        //     let appointmentNr = getCurrentAppointmentNr(steps[activeStep]);

        //     /* unblockAppointment */
        //     let resource;
        //     if (appointmentNr === 1) {
        //         resource = tmstate.selectedResource;
        //     } else if (appointmentNr === 2) {
        //         resource = tmstate.selectedResource_2;
        //     }
        //     let unblockResponse = await unblockAppointment(resource);

        //     tmdispatch({
        //         type: 'WORKONAPPOINTMENTNR',
        //         workOnAppointmentNr: appointmentNr - 1,
        //     });
        // } else if (steps[activeStep] === STEP_TERMIN_BUCHEN) {
        //     tmdispatch({
        //         type: 'WORKONAPPOINTMENTNR',
        //         workOnAppointmentNr: 2, //TODO: must be the last appointment
        //     });
        // }

        if (steps[activeStep] === STEP_TERMIN_BUCHEN) {
            let appointmentNr = 2;
            if (appointmentBooked_1) {
                appointmentNr = 2;
                if (getSelectedResource(2)) {
                    const resource = getSelectedResource(2);
                    await unblockAppointment(resource);
                }
            } else if (appointmentBooked_2) {
                appointmentNr = 1;
                if (getSelectedResource(1)) {
                    const resource = getSelectedResource(1);
                    await unblockAppointment(resource);
                }
            } else if (isAppointmentPostpone()) {
                appointmentNr = 1;
            }
            tmdispatch({
                type: 'WORKONAPPOINTMENTNR',
                workOnAppointmentNr: appointmentNr, //TODO: must be the last appointment
            });
        }

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCancel = async () => {
        /* unblockAppointments */
        if (getSelectedResource(1)) {
            await unblockAppointment(getSelectedResource(1));
        }
        if (getSelectedResource(2)) {
            await unblockAppointment(getSelectedResource(2));
        }

        tmdispatch({
            type: 'RESETCANCELNEWAPPOINTMENTDATA',
        });

        /* reset all resources */
        tmdispatch({
            type: 'WORKONAPPOINTMENTNR',
            workOnAppointmentNr: 1,
        });
        resetAllSelectedResource();
        resetAllSelectedTimeslots();
        resetAllAvailableTimeslots();
        tmdispatch({
            type: 'SETRENEWAPPOINTMENT',
            renewAppointment: false,
        });

        tmdispatch({
            type: 'RESETCATEGORY',
        });

        if (props.checkInVariant) {
            if (props.handleAppointmentsCancelClick) {
                props.handleAppointmentsCancelClick();
            }
        } else {
            navigate(Paths.TERMINE);
        }
    };

    const handleFinish = () => {
        tmdispatch({
            type: 'RESETALL_WITHOUTPERSONALDATA',
        });

        if (props.handleAppointmentsFinishClick) {
            props.handleAppointmentsFinishClick();
        } else {
            navigate(Paths.TERMINE);
        }
    };

    const changeStepsIfMultiAppointments = () => {
        const newSteps = [STEP_KATEGORIE_WAEHLEN];

        const newAppData = tmstate.cancelNewAppointmentData.data;
        let countAPK = 1;
        let completeAPKMode = false;

        let isPostponeAppointment = true;
        let isMissingAPKWorkflow = false;
        if (newAppData.extension) {
            const enrollCode = getEnrollCodeFromExtension(newAppData.extension);
            if (enrollCode.length > 0 || tmstate.withCheckIn) {
                isPostponeAppointment = true;
                isMissingAPKWorkflow = false;
            } else {
                isPostponeAppointment = false;
                isMissingAPKWorkflow = true;
            }
        }

        if (isPostponeAppointment) {
            /* POSTPONE APPOINMENT */
            if (newAppData) {
                const selectedReasonObject = getSelectedReasonDataFromAppointment(newAppData);
                setSelectedResource(1, newAppData);
                tmdispatch({
                    type: 'SELECTCATEGORY',
                    selectedCategory: {
                        name: selectedReasonObject ? '' : newAppData.serviceType[0].coding[0].display,
                        id: newAppData.serviceType[0].coding[0].id,
                        countAPKs: 1,
                        oneMacro: false,
                    },
                });
                if (newAppData.comment) {
                    tmdispatch({
                        type: 'SETREMARK',
                        remark: newAppData.comment,
                    });
                }
                tmdispatch({
                    type: 'SELECTREASON',
                    selectedReason: {
                        ...tmstate.selectedReason,
                        reasonName: selectedReasonObject?.reasonName ? selectedReasonObject?.reasonName : null,
                        reasonId: selectedReasonObject?.reasonId ? selectedReasonObject?.reasonId : null,
                        makroId: selectedReasonObject?.makroId ? selectedReasonObject?.makroId : null,
                    },
                });
            } else {
                /* NEW APPOINTMENT AFTER QUESTIONNAIERE postStoreAction */
                // if (tmstate.selectedCategory.id > 0 && tmstate.selectedCategory.name.length > 0) {
                //     setSelectedResource(1, newAppData);
                // }
            }

            setActiveStep(1);
            setSteps([STEP_KATEGORIE_WAEHLEN, STEP_TERMIN_WAEHLEN, STEP_TERMIN_BUCHEN]);
        }

        if (isMissingAPKWorkflow) {
            const missingAPK = getMissingAPKsFromExtension(newAppData.extension);
            const missingAPKNr = parseInt(missingAPK);
            if (missingAPK.length > 0) {
                completeAPKMode = true;
                countAPK = missingAPKNr + 1;
            } else {
                countAPK = getCountAPKsFromExtension(newAppData.extension);
            }

            if (countAPK > 1) {
                let i;
                for (i = 1; i <= countAPK; i++) {
                    newSteps.push('Termin ' + i.toString());
                }

                newSteps.push(STEP_TERMIN_BUCHEN);

                let canceledAppointment;
                if (completeAPKMode) {
                    canceledAppointment = missingAPKNr;
                } else {
                    canceledAppointment = getAPKNrFromExtension(newAppData.extension);
                }

                if (canceledAppointment === 0) {
                    setAppointmentBooked_1(false);
                    tmdispatch({
                        type: 'WORKONAPPOINTMENTNR',
                        workOnAppointmentNr: 1,
                    });
                } else if (canceledAppointment === 1) {
                    setAppointmentBooked_2(false);
                    tmdispatch({
                        type: 'WORKONAPPOINTMENTNR',
                        workOnAppointmentNr: 2,
                    });
                }

                let bookedAppointment;
                if (completeAPKMode) {
                    bookedAppointment = 0;
                } else {
                    bookedAppointment = getAPKNrFromExtension(newAppData.contained[0].extension);
                }

                if (
                    (newAppData.contained && newAppData.contained[0]?.status === 'booked') ||
                    (newAppData.contained && newAppData.contained[0]?.status === 'fulfilled') ||
                    newAppData.status === appointmentStatus.enteredInError
                ) {
                    let bookedResource;
                    if (completeAPKMode) {
                        bookedResource = newAppData;
                    } else {
                        bookedResource = newAppData.contained[0];
                    }
                    const timeslot = {
                        start: bookedResource.start,
                        end: bookedResource.end,
                    };

                    if (bookedAppointment === 0) {
                        setAppointmentBooked_1(true);
                        // tmdispatch({
                        //     type: 'SETRESOURCE',
                        //     selectedResource: bookedResource,
                        // });

                        // tmdispatch({
                        //     type: 'SELECTTIMESLOT',
                        //     selectedTimeslot: timeslot,
                        // });
                    } else if (bookedAppointment === 1) {
                        setAppointmentBooked_2(true);
                        // tmdispatch({
                        //     type: 'SETRESOURCE_2',
                        //     selectedResource_2: bookedResource,
                        // });

                        // tmdispatch({
                        //     type: 'SELECTTIMESLOT_2',
                        //     selectedTimeslot_2: timeslot,
                        // });
                    }
                    setSelectedResource(bookedAppointment + 1, bookedResource);
                    setSelectedTimeslot(bookedAppointment + 1, timeslot);
                }

                setSteps(newSteps);
                tmdispatch({
                    type: 'SETRENEWAPPOINTMENT',
                    renewAppointment: true,
                });
            } else {
                setSteps([STEP_KATEGORIE_WAEHLEN, STEP_TERMIN_WAEHLEN, STEP_TERMIN_BUCHEN]);
            }
        }
    };

    const EnrollCodeWithLink = () => {
        if (enrollCode) {
            return (
                <>
                    <h3>Termincode: {enrollCode}</h3>
                    <a
                        href={getTermincodeEnrollIdURL(getCurrentBaseURL(location.pathname), enrollCode)}
                        rel="noopener noreferrer"
                    >
                        Link für späteren Aufruf
                    </a>
                </>
            );
        }
        return null;
    };

    const SuccessCards = () => {
        if (appointmentCardData_2) {
            return (
                <Container>
                    <Row>
                        <Col>
                            <br />
                            <EnrollCodeWithLink />
                            <AppointmentCard cardWidth={'25rem'} {...appointmentCardData} showChatIcon={false} />
                        </Col>
                        <Col>
                            <br />
                            <EnrollCodeWithLink />
                            <AppointmentCard cardWidth={'25rem'} {...appointmentCardData_2} showChatIcon={false} />
                        </Col>
                    </Row>
                </Container>
            );
        } else {
            return (
                <CenterDiv>
                    <br />
                    <EnrollCodeWithLink />
                    <AppointmentCard cardWidth={'35rem'} {...appointmentCardData} showChatIcon={false} />
                </CenterDiv>
            );
        }
    };

    const SuccessSummary = () => {
        return (
            <>
                <Lottie options={lottieCheck} height={100} width={100} />
                <SuccessCards />
            </>
        );
    };

    const SuccessMessage = () => {
        if (bookSuccess) {
            return <SuccessSummary />;
        } else {
            return (
                <>
                    <h2>Fehler bei der Terminbuchung. Bitte versuchen Sie es erneut.</h2>
                </>
            );
        }
    };

    const getStep = (label: string, stepscompleted: string) => {
        let completed = false;

        let terminbuchen = false;
        if (stepscompleted === 'all') {
            /* all steps done*/
            completed = true;
        }
        if (stepscompleted === STEP_TERMIN_BUCHEN) {
            /* inkl. Termin 2 completed*/
            terminbuchen = true;
        }

        if (label === STEP_KATEGORIE_WAEHLEN) {
            completed = true;
        } else if (label === 'Termin 1') {
            if (terminbuchen) {
                completed = true;
            } else if (appointmentBooked_1) {
                completed = true;
            }
        } else if (label === 'Termin 2') {
            if (terminbuchen) {
                completed = true;
            } else if (appointmentBooked_2) {
                completed = true;
            }
        }
        return (
            <Step key={label} completed={completed}>
                <StepLabel classes={{ iconContainer: classes.iconContainer }}>{label}</StepLabel>
            </Step>
        );
    };

    useEffect(() => {
        changeStepsIfMultiAppointments();
    }, []);

    useEffect(() => {
        if (appointmentBooked_1) {
            setActiveStep(2);
        } else if (appointmentBooked_2) {
            setActiveStep(1);
        } else {
            setActiveStep(1);
        }
    }, [tmstate.renewAppointment]);

    useEffect(() => {
        if (isTimeslotDateSet(getSelectedTimeslot(1)) && isTimeslotDateSet(getSelectedTimeslot(2))) {
            setNextButtonEnabled(true);
        } else if (steps[activeStep] === STEP_TERMIN_BUCHEN) {
            setNextButtonEnabled(true);
        } else if (steps[activeStep] === STEP_TERMIN_WAEHLEN) {
            setNextButtonEnabled(true);
        }
        return () => {
            setNextButtonEnabled(false);
        };
    }, [tmstate.selectedTimeslots && tmstate.selectedTimeslots.length]);

    // useEffect(() => {
    //     if (isTimeslotDateSet(tmstate.selectedTimeslot) || isTimeslotDateSet(tmstate.selectedTimeslot_2)) {
    //         setNextButtonEnabled(true);
    //     } else if (steps[activeStep] === STEP_TERMIN_BUCHEN) {
    //         setNextButtonEnabled(true);
    //     }
    //     return () => {
    //         setNextButtonEnabled(false);
    //     };
    // }, [tmstate.selectedCategory.name, tmstate.selectedTimeslot, tmstate.selectedTimeslot_2]);

    let stepContent;

    if (activeStep === steps.length) {
        stepContent = (
            <StepperContentDiv>
                <SuccessMessage />
                <br />
                <StickyButtonRowBottomDiv>
                    <StyledCol textAlign="left"></StyledCol>
                    <StyledCol textAlign="right">
                        <StyledButton onClick={handleFinish}>Zurück zur Terminierung</StyledButton>
                    </StyledCol>
                </StickyButtonRowBottomDiv>
            </StepperContentDiv>
        );
    } else {
        stepContent = (
            <StepperContentDiv>
                {getStepContent(steps, activeStep)}
                <StyledRowNoMargin>
                    <StyledCol paddingLeft="0px" textAlign="left">
                        <StyledButton variant="secondary" onClick={() => handleCancel()}>
                            Abbrechen
                        </StyledButton>
                    </StyledCol>
                    <StyledCol sm={{ offset: 6 }} paddingLeft="0px" textAlign="left">
                        <StyledButton
                            disabled={!(steps[activeStep] === STEP_TERMIN_BUCHEN)}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleBack(e)}
                        >
                            Zurück
                        </StyledButton>
                    </StyledCol>
                    <StyledCol paddingLeft="0px" paddingRight="0px" textAlign="right">
                        <StyledButton
                            disabled={!nextButtonEnabled}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleNext(e)}
                        >
                            {steps[activeStep] === STEP_TERMIN_BUCHEN ? 'Jetzt buchen' : 'Weiter'}
                        </StyledButton>
                    </StyledCol>
                </StyledRowNoMargin>
            </StepperContentDiv>
        );
    }

    /* recreate steps when finished */
    let stepscompleted = '';
    if (steps[activeStep] === STEP_TERMIN_BUCHEN) {
        stepscompleted = STEP_TERMIN_BUCHEN;
    } else if (activeStep === steps.length) {
        stepscompleted = 'all';
    }
    return (
        <div>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => getStep(label, stepscompleted))}
            </Stepper>
            <div>{stepContent}</div>
            <BlockingAppointmentModal
                show={blockingModalShow.show}
                onHide={() => setBlockingModalShow({ show: false })}
            />
        </div>
    );
}

export default StepperTerminierungAlternateDate;
