import produce from 'immer';
import { useCallback, useContext } from 'react';
import { isTimeslotDateSet } from '../components/Calendar/TimeSlots';
import CheckInContext from '../context/checkInContext';
import LoginContext from '../context/loginContext';
import TerminierungContext, { IPersonalDataContactPerson } from '../context/terminierungContext';
import { IEnrollData, interpreteKVParams, readCardData } from '../services/RestServices';
import { extractPatData, getPatientResource, getPractitionerResource } from '../services/fhir/FHIRPatientResource';
import {
    getLocationCareUnitIdFromResource,
    getLocationDepartmentIdFromResource,
    getLocationRoomIdFromResource,
    getParticipantIdFromResource,
} from '../utils/appointmentUtils';
import { useAppointmentSelection } from './useAppointmentSelection';

export const usePersonalData = () => {
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { state } = useContext(LoginContext);
    const { checkinstate } = useContext(CheckInContext);
    const { getSelectedTimeslot, getSelectedResource } = useAppointmentSelection();

    const shouldCreateAccount = () => {
        if (tmstate.personalData?.username?.length > 0 && tmstate.personalData?.password?.length > 0) {
            return true;
        }
        return false;
    };

    const getAndSetPersonalData = useCallback(
        async (sessionId: string, activeUserId: string, practitionerRoleId: string) => {
            const patdata = practitionerRoleId
                ? await getPractitionerResource(sessionId, practitionerRoleId)
                : await getPatientResource(sessionId, activeUserId);

            const patdataExt = extractPatData(patdata);

            tmdispatch({
                type: 'SETPERSONALDATA',
                personalData: {
                    ...tmstate.personalData,
                    insuranceNr: patdataExt.insuranceNr,
                    firstName: patdataExt.firstName,
                    lastName: patdataExt.lastName,
                    birthday: patdataExt.birthday, //format YYYY-MM-DD
                    gender: patdataExt.gender,
                    email: patdataExt.emailHome,
                    telephone: patdataExt.telephoneHome,
                    mobilePhone: patdataExt.mobilePhone,
                    academicTitle: patdataExt.academicTitle,
                    street: patdataExt.streetHome,
                    housenr: patdataExt.houseNrHome,
                    city: patdataExt.cityHome,
                    zip: patdataExt.zipHome,
                    country: patdataExt.country,
                    profilePhoto: tmstate.personalData.profilePhoto,
                    plannedStudies: patdataExt.plannedStudies,
                    insuranceName: patdataExt.insuranceName,
                    language: patdataExt.language,
                },
            });
        },
        [tmdispatch, tmstate.personalData],
    );

    const readCardDataAndSetPersonalData = useCallback(
        async (wpName: string) => {
            const readCardResponse = await readCardData(wpName);

            tmdispatch({
                type: 'SETPERSONALDATA',
                personalData: {
                    ...tmstate.personalData,
                    insuranceNr: readCardResponse.insuranceNr ? readCardResponse.insuranceNr : '',
                    firstName: readCardResponse.firstName ? readCardResponse.firstName : '',
                    lastName: readCardResponse.nameLast ? readCardResponse.nameLast : '',
                    birthday: readCardResponse.birthday ? readCardResponse.birthday : '', //format YYYY-MM-DD
                    gender: readCardResponse.gender ? readCardResponse.gender : '',
                    street: readCardResponse.street ? readCardResponse.street : '',
                    housenr: readCardResponse.houseNumber ? readCardResponse.houseNumber : '',
                    city: readCardResponse.city ? readCardResponse.city : '',
                    zip: readCardResponse.zip ? readCardResponse.zip : '',
                    country: readCardResponse.country ? readCardResponse.country : '',
                    mobilePhone: readCardResponse.mobilePhone ? readCardResponse.mobilePhone : '',
                    academicTitle: readCardResponse.academicTitle ? readCardResponse.academicTitle : '',
                    eGK: readCardResponse.eGK ? readCardResponse.eGK : '',
                    state: readCardResponse.state ? readCardResponse.state : '',
                    profilePhoto: tmstate.personalData.profilePhoto ? tmstate.personalData.profilePhoto : '',
                    plannedStudies: readCardResponse.plannedStudies ? readCardResponse.plannedStudies : '',
                    insuranceName: readCardResponse.insuranceName ? readCardResponse.insuranceName : '',
                    language: readCardResponse.language ? readCardResponse.language : '',
                },
            });
        },
        [tmdispatch, tmstate.personalData],
    );

    const readKVDataAndSetPersonalData = useCallback(
        async (txt: string) => {
            const response: any = await interpreteKVParams(txt);
            // console.log(JSON.stringify(response));
            tmdispatch({
                type: 'SETPERSONALDATA',
                personalData: {
                    ...tmstate.personalData,
                    insuranceNr: response.insuranceNr ? response.insuranceNr : '',
                    firstName: response.firstName ? response.firstName : '',
                    lastName: response.nameLast ? response.nameLast : '',
                    birthday: response.birthday ? response.birthday : '', //format YYYY-MM-DD
                    gender: response.gender ? response.gender : '',
                    street: response.street ? response.street : '',
                    housenr: response.houseNumber ? response.houseNumber : '',
                    city: response.city ? response.city : '',
                    zip: response.zip ? response.zip : '',
                    country: response.country ? response.country : '',
                    mobilePhone: response.mobilePhone ? response.mobilePhone : '',
                    academicTitle: response.academicTitle ? response.academicTitle : '',
                    eGK: response.eGK ? response.eGK : '',
                    kvBarcode: response.barcode ? response.barcode : '',
                    state: response.state ? response.state : '',
                    profilePhoto: tmstate.personalData.profilePhoto ? tmstate.personalData.profilePhoto : '',
                    plannedStudies: response.plannedStudies ? response.plannedStudies : '',
                    insuranceName: response.insuranceName ? response.insuranceName : '',
                    language: response.language ? response.language : '',
                },
            });
        },
        [tmdispatch, tmstate.personalData],
    );

    const resetPersonalData = useCallback(async () => {
        tmdispatch({
            type: 'RESETPERSONALDATA',
        });
    }, [tmdispatch]);

    const resetPersonalDataPhysPat = useCallback(async () => {
        tmdispatch({
            type: 'RESETPERSONALDATA_PHYSPAT',
        });
    }, [tmdispatch]);

    const getPlannedStudyParticipation = useCallback(
        (studyCode: string) => {
            const study = tmstate.personalData.plannedStudies.find((p: any) => p.code === studyCode);
            if (study) {
                return study.participate;
            }
            return false;
        },
        [tmstate.personalData.plannedStudies],
    );

    const getEnrollData = useCallback(async () => {
        const selectedTimeslot = getSelectedTimeslot(1);
        let selectedResource = getSelectedResource(1);
        if (isTimeslotDateSet(selectedTimeslot)) {
            selectedResource = produce(selectedResource, (draft: any) => {
                draft.start = selectedTimeslot?.start;
                draft.end = selectedTimeslot?.end;
            });
        }

        let blockId = '';
        if (selectedResource.identifier) {
            for (const id of selectedResource.identifier) {
                if (id.system == 'http://www.principa.siegele-software.com/appBlockId') {
                    blockId = id.value;
                }
            }
        }

        const contactPerson = await getEnrollDataContactPerson();

        const enrollData: IEnrollData = {
            insuranceNr: tmstate.personalData.insuranceNr,
            nameLast: tmstate.personalData.lastName,
            nameFirst: tmstate.personalData.firstName,
            makroId: tmstate.selectedReason.makroId !== undefined ? tmstate.selectedReason.makroId.toString() : '',
            start: selectedTimeslot ? selectedTimeslot.start.toString() : '',
            end: selectedTimeslot ? selectedTimeslot.end.toString() : '',
            birthdate: tmstate.personalData.birthday,
            gender: tmstate.personalData.gender,
            zip: tmstate.personalData.zip,
            city: tmstate.personalData.city,
            street: tmstate.personalData.street,
            streetNr: tmstate.personalData.housenr,
            country: tmstate.personalData.country,
            bsnr: '',
            email: tmstate.personalData.email,
            tel: tmstate.personalData.telephone,
            mobilePhone: tmstate.personalData.mobilePhone,
            academicTitle: tmstate.personalData.academicTitle,
            remark: tmstate.remark,
            ambId: getLocationCareUnitIdFromResource(selectedResource),
            maId: getParticipantIdFromResource(selectedResource),
            departmentId: getLocationDepartmentIdFromResource(selectedResource),
            roomId: getLocationRoomIdFromResource(selectedResource),
            createAccount: shouldCreateAccount(),
            accountName: tmstate.personalData.username,
            accountPWD: tmstate.personalData.password,
            selectedReason: tmstate.selectedReason,
            selectedReasonHints: tmstate.selectedReasonHints,
            selectedPhysicianId: tmstate.selectedPhysician.id,
            sessionId: state.sessionId,
            wpName: checkinstate.wpName ? checkinstate.wpName : '',
            selectedAptType: tmstate.selectedAppointmentType ? tmstate.selectedAppointmentType : '',
            selectedInsurance: tmstate.selectedInsurance ? tmstate.selectedInsurance : '',
            earlierAppointmentWanted: tmstate.earlierAppointmentWanted,
            insuranceName: tmstate.personalData.insuranceName,
            language: tmstate.personalData.language,
            selectedDepartment: tmstate.selectedDepartment?.name ? tmstate.selectedDepartment?.name : '',
            blockId: blockId ? blockId : '',
            contactPerson: contactPerson.lastName ? contactPerson : undefined,
        };

        return enrollData;
    }, [tmdispatch, tmstate]);

    const getEnrollDataPhysPat = useCallback(async () => {
        const selectedTimeslot = getSelectedTimeslot(1);
        let selectedResource = getSelectedResource(1);
        if (isTimeslotDateSet(selectedTimeslot)) {
            selectedResource = produce(selectedResource, (draft: any) => {
                draft.start = selectedTimeslot?.start;
                draft.end = selectedTimeslot?.end;
            });
        }

        const enrollDataPhysPat: IEnrollData = {
            insuranceNr: tmstate.personalDataPhysPat.insuranceNr,
            nameLast: tmstate.personalDataPhysPat.lastName,
            nameFirst: tmstate.personalDataPhysPat.firstName,
            makroId: tmstate.selectedReason.makroId.toString(),
            start: selectedTimeslot ? selectedTimeslot.start.toString() : '',
            end: selectedTimeslot ? selectedTimeslot.end.toString() : '',
            birthdate: tmstate.personalDataPhysPat.birthday,
            gender: tmstate.personalDataPhysPat.gender,
            zip: tmstate.personalDataPhysPat.zip,
            city: tmstate.personalDataPhysPat.city,
            street: tmstate.personalDataPhysPat.street,
            streetNr: tmstate.personalDataPhysPat.housenr,
            country: tmstate.personalDataPhysPat.country,
            bsnr: '',
            email: tmstate.personalDataPhysPat.email,
            tel: tmstate.personalDataPhysPat.telephone,
            mobilePhone: tmstate.personalDataPhysPat.mobilePhone,
            academicTitle: tmstate.personalDataPhysPat.academicTitle,
            remark: tmstate.remark,
            ambId: getLocationCareUnitIdFromResource(selectedResource),
            maId: getParticipantIdFromResource(selectedResource),
            departmentId: getLocationDepartmentIdFromResource(selectedResource),
            roomId: getLocationRoomIdFromResource(selectedResource),
            createAccount: shouldCreateAccount(),
            accountName: tmstate.personalDataPhysPat.username,
            accountPWD: tmstate.personalDataPhysPat.password,
            selectedReason: tmstate.selectedReason,
            selectedReasonHints: tmstate.selectedReasonHints,
            selectedPhysicianId: tmstate.selectedPhysician.id,
            sessionId: state.sessionId,
            wpName: checkinstate.wpName ? checkinstate.wpName : '',
            selectedAptType: tmstate.selectedAppointmentType ? tmstate.selectedAppointmentType : '',
            selectedInsurance: tmstate.selectedInsurance ? tmstate.selectedInsurance : '',
            practitionerRoleId: state.practitionerRoleId,
            insuranceName: tmstate.personalDataPhysPat.insuranceName,
            language: tmstate.personalDataPhysPat.language,
            selectedDepartment: tmstate.selectedDepartment?.name ? tmstate.selectedDepartment?.name : '',
        };

        return enrollDataPhysPat;
    }, [tmdispatch, tmstate]);

    const getEnrollDataContactPerson = useCallback(async () => {
        const enrollDataContactPerson: IPersonalDataContactPerson = {
            firstName: tmstate.personalDataContactPerson.firstName,
            lastName: tmstate.personalDataContactPerson.lastName,
            email: tmstate.personalDataContactPerson.email,
            telephone: tmstate.personalDataContactPerson.telephone,
            street: tmstate.personalDataContactPerson.street,
            housenr: tmstate.personalDataContactPerson.housenr,
            city: tmstate.personalDataContactPerson.city,
            zip: tmstate.personalDataContactPerson.zip,
            country: tmstate.personalDataContactPerson.country,
        };

        return enrollDataContactPerson;
    }, [tmdispatch, tmstate]);

    return {
        getAndSetPersonalData,
        resetPersonalData,
        resetPersonalDataPhysPat,
        getPlannedStudyParticipation,
        readCardDataAndSetPersonalData,
        getEnrollData,
        getEnrollDataPhysPat,
        getEnrollDataContactPerson,
        readKVDataAndSetPersonalData,
    };
};
