import * as React from 'react';
import { AlertOnHide, AlertType } from '../context/alertContext';
import { IDepartmentReasons, IDepartments, InitialLoginType } from '../context/loginContext';
import { ISigData } from '../context/sigpadContext';
import { IHints, IHintsAnswers, IPersonalDataContactPerson } from '../context/terminierungContext';
import { getContextConfig, getRestURL } from '../globals/global';
import { responseErrorHandling } from '../utils/errorHandling';
import { getGenderEN } from '../utils/pcpUtils';
import { addParamToURL } from '../utils/urlUtils';
import { IFreeAppointmentSearch } from './fhir/FHIRAppointment';
import { IPlannedStudies } from './fhir/FHIRPatientResource';

export interface IEnrollData {
    insuranceNr: string;
    nameLast: string;
    nameFirst: string;
    birthdate?: string;
    gender?: string;
    zip?: string;
    city?: string;
    street?: string;
    streetNr?: string;
    country?: string;
    bsnr?: string;
    email?: string;
    tel?: string;
    mobilePhone?: string;
    academicTitle?: string;
    insuranceName?: string;
    language?: string;
    remark?: string;
    ambId?: string;
    maId?: string;
    departmentId?: string;
    roomId?: string;
    makroId: string;
    start: string;
    end: string;
    eGK?: string;
    kvBarcode?: string;
    state?: string;
    createAccount?: boolean;
    accountName?: string;
    accountPWD?: string;
    selectedAptType?: string;
    selectedReason?: IDepartmentReasons;
    selectedReasonHints?: IHintsAnswers[];
    selectedPhysicianId?: number;
    selectedInsurance?: string;
    sessionId?: string;
    bookingCode?: string;
    wpName?: string;
    practitionerRoleId?: string;
    earlierAppointmentWanted?: boolean;
    selectedDepartment?: string;
    behFilter?: string;
    blockId?: string;
    contactPerson?: IPersonalDataContactPerson;
}

export interface IRegisterData {
    insuranceNr?: string;
    nameLast: string;
    nameFirst: string;
    birthdate?: string;
    zip?: string;
    city?: string;
    street?: string;
    streetNr?: string;
    tel?: string;
    email: string;
    gender?: string;
    country?: string;
    mobilePhone?: string;
    academicTitle?: string;
    insuranceName?: string;
    language?: string;
    reason?: string;
    plannedStudies?: IPlannedStudies[];
}

export interface IQuestionnaireJourneyData {
    makros: [
        {
            code: string;
            name: string;
            id: number;
            countAPKs: number;
        },
    ];
    departments: IDepartments[];
    site?: string;
    hints?: IHints[];
    forms: [
        {
            name: string;
            id: string;
            draftURL?: string;
            finalURL?: string;
            url?: string;
            isSignable?: boolean;
            isSigned?: boolean;
        },
    ];
    enrollCode?: string;
}

export interface IPhysiciansFor {
    makroId: string;
    selectedReason?: string;
    selectedInsurance?: string;
    selectedDepartment?: string;
    selectedAptType?: string;
    countDaysToVisualize?: number;
    extendedPhysicians?: boolean;
    startDate?: string;
    currentPhys?: string;
    behFilter?: string;
}

const POST = 'POST';
const GET = 'GET';

const getFetchOptions = (method: string) => ({
    method,
    headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
    },
});

// const getFetchOptionsWithSessionId = (method: string, sessionId: string) => ({
//     method: method,
//     headers: {
//         Accept: '*/*',
//         'Content-Type': 'application/json',
//         Authorization: sessionId,
//     },
// });

const addContextToUrl = (url: string): string => {
    const contextUrl = addParamToURL(url, 'context', getContextConfig());
    return contextUrl;
};

const addLanguageToUrl = (url: string, language: string): string => {
    const contextUrl = addParamToURL(url, 'language', language);
    return contextUrl;
};

export interface IAuthorizeAsync {
    username: string;
    password: string;
    token?: string;
    doubleOptInCode?: string;
}

export const authorizeAsync = async (props: IAuthorizeAsync) => {
    const url = `${getRestURL()}authorize`;

    interface IBodyObj {
        user: string;
        password: string;
        fa2Secret?: string;
        context?: string;
        releaseCode?: string;
    }
    const bodyObj: IBodyObj = { user: props.username, password: props.password };
    if (props.token) {
        bodyObj.fa2Secret = props.token;
    }

    const context = getContextConfig();
    if (context) {
        bodyObj.context = context;
    }

    if (props.doubleOptInCode) {
        bodyObj.releaseCode = props.doubleOptInCode;
    }

    try {
        return await (
            await fetch(url, {
                ...getFetchOptions(POST),
                body: JSON.stringify(bodyObj),
            })
        ).json();
    } catch (error) {
        console.log(error);
        return false;
    }
};

export const getParticipants = async (alertdispatch: React.Dispatch<any>) => {
    let url = `${getRestURL()}getParticipants`;

    url = addContextToUrl(url);

    let resp = [];

    try {
        const re = await fetch(url, {
            ...getFetchOptions(GET),
        });

        resp = await re.json();
    } catch (error) {
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
            alertMessage: {
                alertTitle: 'REST Service Request',
                alertTxt: 'Fehler beim Aufruf von: getParticipants',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
            },
        });

        // console.log(error);
    }
    return resp;
};

export const searchAppointments = async (freeAppointment: IFreeAppointmentSearch) => {
    const url = getRestURL() + 'searchAppointments';
    const date = freeAppointment.date.slice(2);

    if (!freeAppointment.physicianId) {
        freeAppointment.physicianId = '';
    }
    try {
        const jbody: any = { from: date, makroId: freeAppointment.makroId, physicianId: freeAppointment.physicianId };
        if (freeAppointment.selectedDepartment) {
            jbody.selectedDepartment = freeAppointment.selectedDepartment;
        }
        if (freeAppointment.selectedReason) {
            jbody.selectedReason = freeAppointment.selectedReason;
        }
        if (freeAppointment.selectedInsurance) {
            jbody.selectedInsurance = freeAppointment.selectedInsurance;
        }
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jbody),
        });

        return await res.json();
    } catch (error) {
        // console.log(error);
    }
};

export const getPossibleTimelist = async (appointmentResource: any) => {
    const url = getRestURL() + 'getPossibleTimelist';

    try {
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(appointmentResource),
        });

        return await res.json();
    } catch (error) {
        // console.log(error);
    }
};

export const blockAppointment = async (appointmentResource: any) => {
    const url = getRestURL() + 'blockAppointment';

    try {
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(appointmentResource),
        });

        return await res.json();
    } catch (error) {
        // console.log(error);
    }
};

export const unblockAppointment = async (appointmentResource: any) => {
    const url = getRestURL() + 'unblockAppointment';

    try {
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(appointmentResource),
        });

        return await res.json();
    } catch (error) {
        // console.log(error);
    }
};

export const enroll = (registerData: IEnrollData, reason?: string) => {
    const url = getRestURL() + 'enroll';

    let _reason = '';
    if (reason) {
        _reason = reason;
    }
    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            insuranceNr: registerData.insuranceNr,
            patNameLast: registerData.nameLast,
            patNameFirst: registerData.nameFirst,
            patBirthDate: registerData.birthdate,
            patGender: registerData.gender ? getGenderEN(registerData.gender) : '',
            patZip: registerData.zip,
            patCity: registerData.city,
            patStreet: registerData.street,
            patHousenr: registerData.streetNr,
            patCountry: registerData.country,
            bsnr: registerData.bsnr,
            patEmail: registerData.email,
            patTel: registerData.tel,
            patMobilePhone: registerData.mobilePhone,
            patAcademicTitle: registerData.academicTitle,
            insuranceName: registerData.insuranceName,
            language: registerData.language,
            remark: registerData.remark,
            makroId: registerData.makroId,
            maId: registerData.maId,
            ambId: registerData.ambId,
            departmentId: registerData.departmentId,
            roomId: registerData.roomId,
            start: registerData.start,
            end: registerData.end,
            context: getContextConfig(),
            reason: _reason,
            eGK: registerData.eGK ? registerData.eGK : '',
            kvBarcode: registerData.kvBarcode ? registerData.kvBarcode : '',
            state: registerData.state,
            createAccount: registerData.createAccount ? registerData.createAccount : false,
            accountName: registerData.accountName ? registerData.accountName : '',
            accountPWD: registerData.accountPWD ? registerData.accountPWD : '',
            selectedReason: registerData.selectedReason ? registerData.selectedReason : '',
            selectedReasonHints: registerData.selectedReasonHints ? registerData.selectedReasonHints : [],
            selectedAptType: registerData.selectedAptType ? registerData.selectedAptType : '',
            selectedInsurance: registerData.selectedInsurance ? registerData.selectedInsurance : '',
            selectedPhysicianId: registerData.selectedPhysicianId ? registerData.selectedPhysicianId : 0,
            sessionId: registerData.sessionId ? registerData.sessionId : '',
            bookingCode: registerData.bookingCode ? registerData.bookingCode : '',
            wpName: registerData.wpName ? registerData.wpName : '',
            practitionerRoleId: registerData.practitionerRoleId ? registerData.practitionerRoleId : 0,
            earlierAppointmentWanted: registerData.earlierAppointmentWanted
                ? registerData.earlierAppointmentWanted
                : false,
            selectedDepartment: registerData.selectedDepartment ? registerData.selectedDepartment : '',
            behFilter: registerData.behFilter ? registerData.behFilter : '',
            blockId: registerData.blockId ? registerData.blockId : '',
            contactPerson: registerData.contactPerson ? registerData.contactPerson : {},
        }),
    }).then((res) => res.json());
};

export const updateEnroll = (registerData: IEnrollData, reason?: string) => {
    const url = getRestURL() + 'updateEnroll';

    let _reason = '';
    if (reason) {
        _reason = reason;
    }
    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            insuranceNr: registerData.insuranceNr,
            patNameLast: registerData.nameLast,
            patNameFirst: registerData.nameFirst,
            patBirthDate: registerData.birthdate,
            patGender: registerData.gender ? getGenderEN(registerData.gender) : '',
            patZip: registerData.zip,
            patCity: registerData.city,
            patStreet: registerData.street,
            patHousenr: registerData.streetNr,
            patCountry: registerData.country,
            bsnr: registerData.bsnr,
            patEmail: registerData.email,
            patTel: registerData.tel,
            patMobilePhone: registerData.mobilePhone,
            patAcademicTitle: registerData.academicTitle,
            insuranceName: registerData.insuranceName,
            language: registerData.language,
            remark: registerData.remark,
            makroId: registerData.makroId,
            maId: registerData.maId,
            ambId: registerData.ambId,
            departmentId: registerData.departmentId,
            roomId: registerData.roomId,
            start: registerData.start,
            end: registerData.end,
            context: getContextConfig(),
            reason: _reason,
            eGK: registerData.eGK ? registerData.eGK : '',
            kvBarcode: registerData.kvBarcode ? registerData.kvBarcode : '',
            state: registerData.state,
            createAccount: registerData.createAccount ? registerData.createAccount : false,
            accountName: registerData.accountName ? registerData.accountName : '',
            accountPWD: registerData.accountPWD ? registerData.accountPWD : '',
            selectedReason: registerData.selectedReason ? registerData.selectedReason : '',
            selectedReasonHints: registerData.selectedReasonHints ? registerData.selectedReasonHints : [],
            selectedPhysicianId: registerData.selectedPhysicianId ? registerData.selectedPhysicianId : 0,
            sessionId: registerData.sessionId ? registerData.sessionId : '',
            bookingCode: registerData.bookingCode ? registerData.bookingCode : '',
            wpName: registerData.wpName ? registerData.wpName : '',
        }),
    }).then((res) => res.json());
};

export const loadPerspectiveData = async () => {
    const url = getRestURL() + 'getScanPerspective';
    return fetch(url, {
        ...getFetchOptions(GET),
    }).then((res) => res.json());
};

export const getEnrollStatus = (enrollCode: string) => {
    const url = getRestURL() + 'getEnrollStatus?enrollCode=' + enrollCode;

    return fetch(url, {
        ...getFetchOptions(GET),
    }).then((res) => res.json());
};

export const getParkedGDT = async (gdtKey: string) => {
    const url = `${getRestURL()}getParkedGDT?gdtKey=${gdtKey}`;

    try {
        const res = await fetch(url, {
            method: GET,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: getParkedGDT = ' + JSON.stringify(error));
    }
};

export const cancelEnrollment = async (enrollCode: string, alertdispatch: React.Dispatch<any>, comment?: string) => {
    const url = getRestURL() + 'cancelEnrollment';
    let resp = {};
    try {
        const re = await fetch(url, {
            ...getFetchOptions(POST),
            body: JSON.stringify({
                enrollCode: enrollCode,
                comment: comment,
            }),
        });
        resp = await re.json();
    } catch (error) {
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
            alertMessage: {
                alertTitle: 'REST Service Request',
                alertTxt: 'Fehler beim Aufruf von: cancelEnrollment',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
            },
        });
    }
    return resp;
};

export const logout = async (sessionId: string) => {
    const url = `${getRestURL()}logout?sessionId=${sessionId}`;

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).json();
    } catch (error) {
        console.error('FetchServices:: logout = ', error);
        return false;
    }
};

export const addChatEntry = async (enrollCode: string, txt: string, alertdispatch: React.Dispatch<any>) => {
    const url = getRestURL() + 'addChatEntry';

    let resp = [];

    try {
        const re = await fetch(url, {
            ...getFetchOptions(POST),
            body: JSON.stringify({
                enrollCode: enrollCode,
                txt: txt,
            }),
        });

        resp = await re.json();
    } catch (error) {
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
            alertMessage: {
                alertTitle: 'REST Service Request',
                alertTxt: 'Fehler beim Aufruf von: addChatEntry -> Konnte Nachricht nicht senden',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
            },
        });
        // console.log(error);
    }
    return resp;
};

export const addChatEntryAppointment = async (
    appointmentId: string,
    txt: string,
    alertdispatch: React.Dispatch<any>,
) => {
    const url = getRestURL() + 'addChatEntry';

    let resp = [];

    try {
        const re = await fetch(url, {
            ...getFetchOptions(POST),
            body: JSON.stringify({
                appointmentId: appointmentId,
                txt: txt,
            }),
        });

        resp = await re.json();
    } catch (error) {
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
            alertMessage: {
                alertTitle: 'REST Service Request',
                alertTxt: 'Fehler beim Aufruf von: addChatEntryAppointment -> Konnte Nachricht nicht senden',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
            },
        });
        // console.log(error);
    }
    return resp;
};

export const registerUser = async (enrollCode: string, email: string, alertdispatch: React.Dispatch<any>) => {
    const url = getRestURL() + 'registerUser';

    let resp = [];

    try {
        const re = await fetch(url, {
            ...getFetchOptions(POST),
            body: JSON.stringify({
                enrollCode: enrollCode,
                email: email,
            }),
        });

        resp = await re.json();
    } catch (error) {
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
            alertMessage: {
                alertTitle: 'REST Service Request',
                alertTxt: 'Fehler beim Aufruf von: registerUser -> Es ist ein Fehler bei der Registrierung aufgetreten',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
            },
        });
        // console.log(error);
    }
    return resp;
};

export const registerUserDirect = (registerData: IRegisterData) => {
    const url = getRestURL() + 'createHOMEPatientDirect';
    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            patNameLast: registerData.nameLast,
            patNameFirst: registerData.nameFirst,
            patBirthDate: registerData.birthdate,
            patZip: registerData.zip,
            patCity: registerData.city,
            patStreet: registerData.street,
            patHousenr: registerData.streetNr,
            patTel: registerData.tel,
            patEMail: registerData.email,
            patGender: registerData.gender ? getGenderEN(registerData.gender) : '',
            patMobilePhone: registerData.mobilePhone,
            patAcademicTitle: registerData.academicTitle,
            context: getContextConfig(),
            patCountry: registerData.country ? registerData.country : '', //eventuell getCountryEN() verwendern
            insuranceNr: registerData.insuranceNr,
            insuranceName: registerData.insuranceName,
            language: registerData.language,
            reason: registerData.reason,
        }),
    }).then((res) => res.json());
};

export const consignation = async (
    userId: string,
    sessionId: string,
    consignation: string,
    isAddConsignation?: boolean,
    urlTerms?: string,
) => {
    const url = getRestURL() + 'consignation';

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            userId,
            sessionId,
            consignation: consignation,
            urlTerms: urlTerms ? urlTerms : '',
            isAddConsignation: isAddConsignation && isAddConsignation === true ? true : false,
        }),
    }).then((res) => res.json());
};

export const checkConsignation = async (sessionId: string) => {
    const url = `${getRestURL()}checkConsignation?sessionId=${sessionId}`;

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).json();
    } catch (error) {
        console.error('FetchServices:: checkConsignation = ', error);
        return false;
    }
};

export const getPossibleMacros = async (sessionId: string) => {
    const url = `${getRestURL()}getPossibleMakros?sessionId=${sessionId}`;

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).json();
    } catch (error) {
        console.error('FetchServices:: getPossibleMacros = ', error);
        return false;
    }
};

export const setPwd = async (
    state: InitialLoginType,
    alertdispatch: React.Dispatch<any>,
    password: string,
    oldPassword?: string,
) => {
    const url = getRestURL() + 'setPwd';
    const sessionId = state.sessionId;

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            sessionId,
            password,
            oldPassword,
        }),
    })
        .then((res) => res.json())
        .catch((error) => {
            responseErrorHandling({
                response: error,
                alertdispatch: alertdispatch,
            });
        });
};

export const setUsername = async (
    state: InitialLoginType,
    alertdispatch: React.Dispatch<any>,
    username: string,
    oldUsername?: string,
) => {
    const url = getRestURL() + 'setUsername';
    const sessionId = state.sessionId;

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            sessionId,
            username,
            oldUsername,
        }),
    })
        .then((res) => res.json())
        .catch((error) => {
            responseErrorHandling({
                response: error,
                alertdispatch: alertdispatch,
            });
        });
};

export const deleteAccount = async (state: InitialLoginType, alertdispatch: React.Dispatch<any>) => {
    const url = getRestURL() + 'deleteAccount';
    const sessionId = state.sessionId;

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            sessionId,
        }),
    })
        .then((res) => res.json())
        .catch((error) => {
            responseErrorHandling({
                response: error,
                alertdispatch: alertdispatch,
            });
        });
};

export const generateSecret = async (sessionId: string) => {
    const url = getRestURL() + 'generateSecret';

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            sessionId,
        }),
    }).then((res) => res.json());
};

export const removeSecret = async (sessionId: string) => {
    const url = getRestURL() + 'removeSecret';

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            sessionId,
        }),
    }).then((res) => res.json());
};

export const checkSecret = (state: InitialLoginType, alertdispatch: React.Dispatch<any>) => {
    const url = getRestURL() + 'checkSecret?sessionId=' + state.sessionId;

    return fetch(url, { ...getFetchOptions(GET) })
        .then((res) => res.json())
        .catch((error) => {
            responseErrorHandling({
                response: error,
                alertdispatch: alertdispatch,
            });
        });
};

export const getBarcodeForApp = async (sessionId?: string) => {
    let url = `${getRestURL()}barcode4App?sessionId=${sessionId}`;

    url = addContextToUrl(url);

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).json();
    } catch (error) {
        // console.log('Rest-Service: getBarcodeForApp = ', error);
        return false;
    }
};

export const addPlannedStudy = async (sessionId: string, plannedStudy: string) => {
    const url = `${getRestURL()}addPlannedStudy?sessionId=${sessionId}&plannedStudy=${plannedStudy}`;

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).json();
    } catch (error) {
        // console.log('Rest-Service: getTermsHTML = ', error);
        // return false;
        return 'Error: Rest-Service: addPlannedStudy = ' + error;
    }
};

export const getTermsHTML = async (sessionId: string, addUrl?: string) => {
    let url = `${getRestURL()}termsHTML?sessionId=${sessionId}`;
    if (addUrl) {
        url = url + `&url=${encodeURIComponent(addUrl)}`;
    }

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).text();
    } catch (error) {
        // console.log('Rest-Service: getTermsHTML = ', error);
        // return false;
        return 'Error: Rest-Service: getTermsHTML = ' + error;
    }
};

export const getPrivacyHTML = async (sessionId: string) => {
    const url = `${getRestURL()}privacyHTML?sessionId=${sessionId}`;

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).text();
    } catch (error) {
        // console.log('Rest-Service: getPrivacyHTML = ', error);
        // return false;
        return 'Error: Rest-Service: getPrivacyHTML = ' + error;
    }
};

export const getJourney = async (sessionId: string, patId: string, parentId?: string, encounterId?: string) => {
    let url = `${getRestURL()}getJourney?sessionId=${sessionId}&patId=${patId}`;
    if (parentId) {
        url = url + `&parentId=${parentId}`;
    }
    if (encounterId) {
        url = url + '&encounterId=' + encounterId;
    }

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).json();
    } catch (error) {
        return 'Error: Rest-Service: getJourney = ' + error;
    }
};

export const getAppProperties = async () => {
    let url = getRestURL() + 'appProperties';

    url = addContextToUrl(url);
    url = addLanguageToUrl(url, (window as any).app.language);

    return fetch(url, { ...getFetchOptions(GET) })
        .then((res) => res.json())
        .catch((err) => console.error('Rest-Service: getAppProperties = ', err));
};

export const setActivePatId = async (sessionId: string, patId?: string) => {
    const url = getRestURL() + 'setActivePatId';

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            sessionId,
            patId,
        }),
    }).then((res) => res.json());
};

export const getPasswordReset = async (email: string, userName?: string) => {
    const url = getRestURL() + 'inquireNewPassword';

    return fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            email,
            userName,
        }),
    }).then((res) => res.json());
};

export const callRestMethod = async (
    state: InitialLoginType,
    alertdispatch: React.Dispatch<any>,
    methodName: string,
) => {
    const url = `${getRestURL()}${methodName}?sessionId=${state.sessionId}`;

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).text();
    } catch (error) {
        console.log('Rest-Service: callRestMethod = ', error);
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
        });
        return false;
    }
};

export const setPatientProfilePicture = async (
    state: InitialLoginType,
    alertdispatch: React.Dispatch<any>,
    base64: string,
) => {
    try {
        const url = getRestURL() + 'storePatientPhoto';
        const res = await fetch(url, {
            ...getFetchOptions(POST),
            body: JSON.stringify({
                sessionId: state.sessionId,
                patId: state.userId,
                pictureData: base64,
            }),
        });
        const json = await res.json();
        return json;
    } catch (error) {
        console.log('FetchServices:: storePatientPhoto = ' + JSON.stringify(error));
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
        });
    }
};

export const removePatientProfilePicture = async (state: InitialLoginType, alertdispatch: React.Dispatch<any>) => {
    try {
        const url = getRestURL() + 'removePatientPhoto';
        const res = await fetch(url, {
            ...getFetchOptions(POST),
            body: JSON.stringify({
                sessionId: state.sessionId,
                patId: state.userId,
            }),
        });
        const json = await res.json();
        return json;
    } catch (error) {
        console.log('FetchServices:: removePatientPhoto = ' + JSON.stringify(error));
        responseErrorHandling({
            response: error,
            alertdispatch: alertdispatch,
        });
    }
};

export const getIFrameURL = async (formId: string, patId: string) => {
    const url = `${getRestURL()}finalizeIFRAMEURL?form=${formId}&patId=${patId}`;
    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).text();
    } catch (error) {
        // console.log('Rest-Service: getPrivacyHTML = ', error);
        return false;
    }
};

export const executeRule = async (
    userId: string,
    sessionId: string,
    ruleName: string,
    ruleParam: string,
    persInfo: string,
) => {
    const url = getRestURL() + 'executeRule';

    if (userId === undefined || userId.length <= 0 || sessionId === undefined || sessionId.length <= 0) {
        return;
    }

    try {
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: userId,
                sessionId: sessionId,
                ruleName: ruleName,
                ruleParam: ruleParam,
                persInfo: persInfo,
            }),
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: executeRule = ' + JSON.stringify(error));
    }
};

export const verifyCovidCert = async (sessionId: string, qrContent: string, type: string) => {
    const url = getRestURL() + 'verifyCovidCert';

    if (sessionId === undefined || sessionId.length <= 0) {
        return;
    }

    try {
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sessionId: sessionId,
                qrContent: qrContent,
                type: type,
            }),
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: verifyCovidCert = ' + JSON.stringify(error));
    }
};

export const taskList = async (state: InitialLoginType, type: string) => {
    const url = getRestURL() + 'taskList';
    const userId = state.userId;
    const sessionId = state.sessionId;

    if (sessionId === undefined || sessionId.length <= 0) {
        return;
    }

    try {
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sessionId: sessionId,
                userId: userId,
                type: type,
            }),
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: taskList = ' + JSON.stringify(error));
    }
};

export const callTaskListFunction = async (state: InitialLoginType, callFunction: string, parameter: any) => {
    if (callFunction == undefined || callFunction.length <= 0) {
        return;
    }

    const url = getRestURL() + callFunction;
    const userId = state.userId;
    const sessionId = state.sessionId;

    if (sessionId === undefined || sessionId.length <= 0) {
        return;
    }

    try {
        const res = await fetch(url, {
            method: POST,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sessionId: sessionId,
                userId: userId,
                ...parameter,
            }),
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: callTaskListFunction = ' + JSON.stringify(error));
    }
};

export const getQuestionnaireJourney = async (reason: string, auth?: string, wpName?: string, behFilter?: string) => {
    let authorization = '';
    let wp = '';
    let bh = '';
    if (auth && auth.length > 0) {
        // authorization = encodeURIComponent('&authorization=' + auth);
        authorization = '&authorization=' + encodeURIComponent(auth);
    }
    if (wpName && wpName.length > 0) {
        wp = '&wpName=' + encodeURIComponent(wpName);
    }
    if (behFilter) {
        bh = '&behFilter=' + behFilter;
    }

    let url = `${getRestURL()}getQuestionnaireJourney?reason=${reason}${authorization}${wp}${bh}`;

    url = addLanguageToUrl(url, (window as any).app.language);

    try {
        const res = await fetch(url, {
            method: GET,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: getQuestionnaireJourney = ' + JSON.stringify(error));
    }
};

export const getURLToCall = async (wpName: string, reason?: string, userId?: string, t0?: string, otp?: string) => {
    let url = `${getRestURL()}getURLToCall?wpName=${wpName}`;
    if (reason) {
        url += '&reason=' + reason;
    }
    if (userId) {
        url += '&userId=' + userId;
    }
    if (t0) {
        url += '&t0=' + t0;
    }
    if (otp) {
        url += '&otp=' + otp;
    }

    try {
        const res = await fetch(url, {
            method: GET,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: getURLToCall = ' + JSON.stringify(error));
    }
};

export const getTabletURL = async (id: string) => {
    const url = `${getRestURL()}getTabletURL?id=${id}`;

    try {
        const res = await fetch(url, {
            method: GET,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: getTabletURL = ' + JSON.stringify(error));
    }
};

export const readCardData = async (wpName: string) => {
    const url = `${getRestURL()}readCardData?wpName=${wpName}`;

    try {
        const res = await fetch(url, {
            method: GET,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: readCardData = ' + JSON.stringify(error));
    }
};

export const addSignatureToPDF = (sigData: ISigData, diagnosticReportData: any) => {
    const url = getRestURL() + 'addSignatureToPDF';

    let reportData = '';
    if (diagnosticReportData) {
        if (diagnosticReportData.data?.id && diagnosticReportData.data?.id.length > 0) {
            reportData = 'DiagnosticReport/' + diagnosticReportData.data.id;
        } else if (diagnosticReportData.id) {
            reportData = 'DiagnosticReport/' + diagnosticReportData.id;
        }
    }

    return fetch(url, {
        ...getFetchOptions(POST),
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
        },
        /* noch nicht spezifiziert, eventuell noch zu aendern */
        body: JSON.stringify({
            report: reportData,
            pictureData: sigData.pictureData,
            certId: sigData.certId,
            rsaSignature: sigData.rsaSignature,
            signData: sigData.signData,
        }),
    }).then((res) => res.json());
};

export const getPhysiciansFor = async (props: IPhysiciansFor) => {
    // let makroId = '';

    // const makroIdArray: string[] = [];

    // filteredReasons.forEach((reason) => {
    //     makroIdArray.push(reason.makroId.toString());
    // });

    // makroId = makroIdArray.join(',');

    let url = `${getRestURL()}getPhysiciansFor?makro=${props.makroId}&selectedReason=${
        props.selectedReason
    }&selectedInsurance=${props.selectedInsurance}&selectedDepartment=${props.selectedDepartment}&extendedPhysicians=${
        props.extendedPhysicians
    }`;
    if (props.selectedAptType) {
        url += `&selectedAptType=${props.selectedAptType}`;
    }

    if (props.countDaysToVisualize && props.countDaysToVisualize > 0) {
        url = url + `&countDaysToVisualize=${props.countDaysToVisualize}`;
    }

    if (props.startDate && props.startDate.length > 0) {
        url = url + `&startDate=${props.startDate}`;
    }

    if (props.currentPhys && props.currentPhys.length > 0) {
        url = url + `&currentPhys=${props.currentPhys}`;
    }
    if (props.behFilter) {
        url = url + '&behFilter=' + props.behFilter;
    }

    url = addLanguageToUrl(url, (window as any).app.language);

    try {
        const res = await fetch(url, {
            method: GET,
            headers: {
                Accept: '*/*',
                'Content-Type': 'application/json',
            },
        });

        return await res.json();
    } catch (error) {
        console.log('FetchServices:: getPhysiciansFor = ' + JSON.stringify(error));
    }
};

export const interpreteKVParams = async (scantxt: string, reason?: string) => {
    let url = `${getRestURL()}getBarcodeData`;
    if (reason) {
        url += '?reason=' + reason;
    }
    const res = await fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            barcode: scantxt,
        }),
    });
    const json = await res.json();
    return json;
};

export const getOTPFromAppID = async (appId: string, secret: string) => {
    const url = `${getRestURL()}getOTPFromAppId`;
    const res = await fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            appId: appId,
            secret: secret,
        }),
    });
    const json = await res.json();
    return json;
};

export const pushActivePatient = async (patId: string, sessionId: string) => {
    const url = `${getRestURL()}pushLastPatId`;
    const res = await fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            patId: patId,
            sessionId: sessionId,
        }),
    });
    const json = await res.json();
    return json;
};

export const getJitsiJWTToken = async (sessionId: string, room: string, isAdmin: boolean) => {
    const url = `${getRestURL()}getJitsiJWTToken?sessionId=${sessionId}&room=${room}&isAdmin=${isAdmin}`;

    try {
        return await (
            await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: '*/*',
                },
            })
        ).json();
    } catch (error) {
        return 'Error: Rest-Service: getJitsiJWTToken = ' + error;
    }
};

export const checkAppointment = async (userId: string, otp: string, t0: string, reason: string) => {
    const url = `${getRestURL()}checkAppointment`;
    const res = await fetch(url, {
        ...getFetchOptions(POST),
        body: JSON.stringify({
            userId: userId,
            otp: otp,
            t0: t0,
            reason: reason,
        }),
    });
    const json = await res.json();
    return json;
};

export const checkEnrollCodeViaWS = async (enrollCode: string) => {
    const url = `${getRestURL()}checkEnrollCodeViaWS?enrollCode=${enrollCode}`;
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: '*/*',
        },
    });
    const json = await res.json();
    return json.isActive ? true : false;
};
